import React, {Fragment, Component}  from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';

import gris from '../Images/gris.png';


class Catalogo extends Component {

    constructor(props){
        super(props);
        this.state = {}
      }

      render (){
        if (!this.props.producto) {
            return (
                <Fragment>
                    <div className="container center-align" style={{marginTop:'20%', marginBottom:'20%'}}>
                        <div class="preloader-wrapper big active">
                            <div class="spinner-layer spinner-red darken-4-only">
                                <div class="circle-clipper left">
                                    <div class="circle"></div>
                                </div>
                                <div class="gap-patch">
                                    <div class="circle"></div>
                                </div>
                                <div class="circle-clipper right">
                                    <div class="circle"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>
            );
        } else {
            return (
                <div className="container row" style={{marginTop:"50px"}}>
                    {this.props.producto.map(producto => (
                        <div className="col s12 m4 l4">
                            <a href={`/producto/${producto.id}`}>
                                <div >
                                    {producto.urlImagen === undefined || producto.urlImagen === "" ? (<img src={gris} alt="" style={{width:"100%"}} />) : (<img src={producto.urlImagen} alt="" style={{width:"100%"}} />)}
                                    <p>$ {producto.precio}</p>
                                    <p>{producto.nombre}</p>
                                </div>
                            </a>
                        </div>
                        
                    ))}
                </div>
            )
        }
    }
}

Catalogo.protoType = {
    firestor : PropTypes.object.isRequired
}

export default compose(
    firestoreConnect(props => [ 
        {
            collection: 'producto'
        }
    ]),
    connect(({ firestore: { ordered }}, props ) => ({
        producto: ordered.producto
    }))
)(Catalogo);

import CryptoJS from "react-native-crypto-js";
import axios from 'axios';

const restAPI = {
    conectar: async (token, data) => {
        var ciphertext = CryptoJS.AES.encrypt("key_kLk5kE7ypVw6jkU1yWrwxg", 'Y2Fhdg==').toString();
        let body = {
            auth: ciphertext,
            token: token.id,
            nombre: data.nombre,
            correo: data.correo,
            cel: data.telefono,
            productos: [data.producto],
            cantidades: [1],
            precios: [parseInt(data.precio)],
        };
        let url = `https://www.api-2.tourdiviaggio.com/auth/tour-di-viaggio/conekta`;
        let request = {
            method:'POST',
            body: JSON.stringify(body),
            headers: {
                "Content-type":"application/json; charset=UTF-8"
            }
        }

        let response = await fetch(url, request);
        let json = await response.json();
        return json;
    },
    upload: async data => {
        var ciphertext = CryptoJS.AES.encrypt("key_kLk5kE7ypVw6jkU1yWrwxg", 'Y2Fhdg==').toString();
        let formData  = new FormData();
        formData.append("auth", ciphertext);
        if (data.data.length !== undefined) {
            formData.append("original", data.data[3]);
            formData.append("chica", data.data[0]);
            formData.append("mediana", data.data[1]);
            formData.append("grande", data.data[2]);
        } else {
            formData.append("data", data.data);
        }
        formData.append("name", data.name);
        formData.append("case", data.caso);

        let url = 'https://www.api-2.tourdiviaggio.com/auth/sistema-nail-art/firebase-upload';
        let response = axios.post(url, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(resp => {
            return JSON.parse(resp.request.response);
        });

        let json = await response;
        return json;
    }
}

export default restAPI;
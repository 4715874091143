import React, {Fragment, Component}  from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import Header from '../../../Components/SideNav';
import { Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';


class Categoria extends Component {
    
    constructor(props){
        super(props)
        this.state = {
            nombre: ''
        }
    }


    render(){
        if (!this.props.categoria) {
            return (
                <Fragment>
                    <Header />
                    <div className="container center-align" style={{marginTop:'20%', marginBottom:'20%'}}>
                        <div class="preloader-wrapper big active">
                            <div class="spinner-layer spinner-red darken-4-only">
                                <div class="circle-clipper left">
                                    <div class="circle"></div>
                                </div>
                                <div class="gap-patch">
                                    <div class="circle"></div>
                                </div>
                                <div class="circle-clipper right">
                                    <div class="circle"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>
            );
        } else {
            return (
                <Fragment>
                    <Header mensaje="CATEGORIAS IMAGENES" />

                    <div className="container" style={{padding: '30px 0 30px 0'}}>
                        {this.props.categoria.length === '0' ? (
                            <span></span>
                        ) : (
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>Nombre</th>
                                        <th>Acciones</th>
                                    </tr>
                                </thead>
                                <tbody id="font">
                                    {this.props.categoria.map(dato => (
                                        <tr key={dato.id}>
                                            <td>{dato.nombre}</td>
                                            <td>
                                                <Link style={{marginLeft:'14%'}} to={`/admin/imagenes-productos/${dato.id}`}><i class="material-icons" style={{color:'#e57373 '}}>remove_red_eye</i></Link>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        )}
                    </div>
                    
                </Fragment>
            );
        }
    }
}

Categoria.protoType = {
    firestor : PropTypes.object.isRequired
}

export default compose(
    firestoreConnect(props => [ 
        {
            collection: 'categoria'
        }
    ]),
    connect(({ firestore: { ordered }}, props ) => ({
        categoria: ordered.categoria
    }))
)(Categoria);
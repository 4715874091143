import React, {Fragment, Component} from 'react';
import M from 'materialize-css';

import img1 from "../Images/header/V1.png"

class Header extends Component {
    constructor (props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount = () => {
        var elems = document.querySelectorAll('.sidenav');
        M.Sidenav.init(elems);
    }


    render (){
        return [
            <Fragment>        
                <nav className='header' style={{boxShadow:"none"}} >
                    <div class="nav-wrapper row" style={{backgroundColor:"#fff", marginBottom:"0px"}}>
                        <div className='col s3 m3 l12 center box_header'>
                            <a href="/">
                                <img src={img1} className="img_header" alt=""/>
                            </a>
                            
                        </div>
                        
                        <div className='col s3 m3 l12 offset-s6 offset-m6 '>
                            <div style={{display:"flex", justifyContent:"center"}}>
                                <a href="#!" data-target="mobile-demo" class="sidenav-trigger">
                                    <i class="material-icons">
                                        menu
                                    </i>
                                </a>
                                <ul class="right hide-on-med-and-down texto_header">
                                    <li><a href="/">INICIO</a></li>                         
                                    <li><a href="/nosotros">NOSOTROS</a></li>
                                    <li><a href="/tours">TOURS</a></li>
                                    <li><a href="/contacto">CONTACTO</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </nav>

                <ul class="sidenav" id="mobile-demo">
                        <li><a href="/">INICIO</a></li>                         
                        <li><a href="/nosotros">NOSOTROS</a></li>
                        <li><a href="/tours">TOURS</a></li>
                        <li><a href="/contacto">CONTACTO</a></li>
                </ul>

            </Fragment>
        ]
    }
}

export default Header;

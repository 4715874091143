import React, { Fragment, useState, useEffect } from "react";
import $ from "jquery";

import M from "materialize-css";

import icon1 from "../../Images/iconos/Tours/TI4.png";
import icon2 from "../../Images/iconos/Tours/TI5.png";
import icon3 from "../../Images/iconos/Tours/TI3.png";
import icon4 from "../../Images/iconos/Tours/TI2.png";

import img1 from "../../Images/escritorio/Montanias/ME1.png";
import img2 from "../../Images/escritorio/Montanias/ME2.jpg";
import img3 from "../../Images/escritorio/Montanias/ME3.jpg";
import img4 from "../../Images/escritorio/Montanias/ME4.jpg";
import img5 from "../../Images/escritorio/Montanias/ME5.png";
import img6 from "../../Images/escritorio/Montanias/ME6.jpg";
import img7 from "../../Images/escritorio/Montanias/ME7.jpg";
import img8 from "../../Images/escritorio/Montanias/ME8.png";
import img9 from "../../Images/escritorio/Montanias/ME9.png";

import img10 from "../../Images/escritorio/Montanias/recortes/ME3C.jpg";
import img11 from "../../Images/escritorio/Montanias/recortes/ME4C.jpg";
import img12 from "../../Images/escritorio/Montanias/recortes/ME6C.jpg";
import img13 from "../../Images/escritorio/Montanias/recortes/ME9C.png";

const MontaniasMagicas = () => {
  const [imagenSeleccionada, setImagenSeleccionada] = useState(img1);
  const [imagenesMostrar, setImagenesMostrar] = useState(7);

  const imagenes = [img1, img2, img3, img4, img5, img6, img7, img8, img9];

  useEffect(() => {
    var elems = document.querySelectorAll(".carousel");
    M.Carousel.init(elems, {
      onCycleTo: (e) => {
        setImagenSeleccionada(imagenes[e.id]);
      },
    });
    if (window.screen.width < 851) {
      setImagenesMostrar(3);
    }
    $(window).scroll(function () {
      $("#efecto").each(function () {
        let efecto = $(this).offset().top;
        let topOfWindow = $(window).scrollTop();
        if (efecto < topOfWindow + 800) {
          $(this).addClass("fadeIn");
        }
      });
    });
  }, []);
  return (
    <Fragment>
      <div>
        <div className="cont_7">
          <p className="titulo_8 center">7 MONTAÑAS MÁGICAS</p>
          <div>
            <img src={imagenSeleccionada} alt="" style={{ width: "100%" }} />
          </div>

          <div class="carousel">
            <div id="0" class="carousel-item">
              {" "}
              <img
                src={img1}
                alt=""
                onClick={() => {
                  setImagenSeleccionada(img1);
                }}
                className="img_tour_3"
              />
            </div>
            <div id="1" class="carousel-item">
              {" "}
              <img
                src={img2}
                alt=""
                onClick={() => {
                  setImagenSeleccionada(img2);
                }}
                className="img_tour_3"
              />
            </div>
            <div id="2" class="carousel-item">
              {" "}
              <img
                src={img3}
                alt=""
                onClick={() => {
                  setImagenSeleccionada(img3);
                }}
                className="img_tour_3"
              />
            </div>
            <div id="3" class="carousel-item">
              {" "}
              <img
                src={img4}
                alt=""
                onClick={() => {
                  setImagenSeleccionada(img4);
                }}
                className="img_tour_3"
              />
            </div>
            <div id="4" class="carousel-item">
              {" "}
              <img
                src={img5}
                alt=""
                onClick={() => {
                  setImagenSeleccionada(img5);
                }}
                className="img_tour_3"
              />
            </div>
            <div id="5" class="carousel-item">
              {" "}
              <img
                src={img6}
                alt=""
                onClick={() => {
                  setImagenSeleccionada(img6);
                }}
                className="img_tour_3"
              />
            </div>
            <div id="6" class="carousel-item">
              {" "}
              <img
                src={img7}
                alt=""
                onClick={() => {
                  setImagenSeleccionada(img7);
                }}
                className="img_tour_3"
              />
            </div>
            <div id="7" class="carousel-item">
              {" "}
              <img
                src={img8}
                alt=""
                onClick={() => {
                  setImagenSeleccionada(img8);
                }}
                className="img_tour_3"
              />
            </div>
            <div id="8" class="carousel-item">
              {" "}
              <img
                src={img9}
                alt=""
                onClick={() => {
                  setImagenSeleccionada(img9);
                }}
                className="img_tour_3"
              />
            </div>
          </div>
        </div>

        <div className="caja_4">
          <div
            id="efecto"
            style={{
              display: "flex",
              justifyContent: "center",
              flexWrap: "wrap",
            }}
          >
            <div className="caja_5">
              <img
                src={icon1}
                alt=""
                style={{ width: "42px", height: "42px" }}
              />
              <p
                className="titulo_5"
                style={{ color: "#148D8D", margin: "0px" }}
              >
                DURACION
              </p>
              <p
                className="texto_1"
                style={{ margin: "0px", color: "#555555" }}
              >
                2 horas aprox.
              </p>
            </div>
            {/* <div className="caja_5">
              <img
                src={icon2}
                alt=""
                style={{ width: "28px", height: "43px" }}
              />
              <p
                className="titulo_5"
                style={{ color: "#D83951", margin: "0px" }}
              >
                PRECIO
              </p>
              <p
                className="texto_1"
                style={{
                  margin: "0px",
                  color: "#555555",
                  textDecoration: "line-through",
                }}
              >
                Desde $60 USD
              </p>
              <p
                className="texto_6"
                style={{ margin: "0px", color: "#D83951" }}
              >
                <b>OFERTA $40 USD</b>
              </p>
            </div> */}
            <div className="caja_5">
              <img
                src={icon3}
                alt=""
                style={{ width: "73px", height: "44px" }}
              />
              <p
                className="titulo_5"
                style={{ color: "#FDBB41", margin: "0px" }}
              >
                GRUPO
              </p>
              <p
                className="texto_1"
                style={{ margin: "0px", color: "#555555" }}
              >
                7 personas max.
              </p>
            </div>
          </div>
        </div>

        <div className="cont_5 texto_1" style={{ color: "#555555" }}>
          <p className="box_4">
            A solo aproximadamente 30 minutos del centro de Las Vegas,
            encontraremos las Siete Montañas Mágicas – Seven Magic Mountains
          </p>
          <p className="box_4">
            Este mágico lugar se localiza en el Desierto de Mojave y es una
            instalación de arte del artista suizo Ugo Rondinone que consiste en
            7 tótems de rocas de hasta 10.6 m de altura las cuales se erigen en
            medio del desierto. Se calcula que durante 2 años de exposición de
            la obra, ha sido vista por más de 16 millones de vehículos, que
            pasan por la carretera junto a la llamativo conjunto escultural.
          </p>

          <div className="row cont_6">
            <div className="col s12 m4" style={{ padding: "0px" }}>
              <p className="titulo_7" style={{ marginBottom: "15px" }}>
                INCLUYE
              </p>
              <div
                style={{
                  display: "flex",
                  justifyContent: "left",
                  alignItems: "center",
                }}
              >
                <img
                  src={icon4}
                  alt=""
                  style={{ width: "30px", height: "30px" }}
                />
                <p
                  className="texto_1"
                  style={{ color: "#555555", margin: "5px 10px" }}
                >
                  Salida desde tu hotel
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "left",
                  alignItems: "center",
                }}
              >
                <img
                  src={icon4}
                  alt=""
                  style={{ width: "30px", height: "30px" }}
                />
                <p
                  className="texto_1"
                  style={{ color: "#555555", margin: "5px 10px" }}
                >
                  Traslado ida y vuelta
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "left",
                  alignItems: "center",
                }}
              >
                <img
                  src={icon4}
                  alt=""
                  style={{ width: "30px", height: "30px" }}
                />
                <p
                  className="texto_1"
                  style={{ color: "#555555", margin: "5px 10px" }}
                >
                  Agua
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "left",
                  alignItems: "center",
                }}
              >
                <img
                  src={icon4}
                  alt=""
                  style={{ width: "30px", height: "30px" }}
                />
                <p
                  className="texto_1"
                  style={{ color: "#555555", margin: "5px 10px" }}
                >
                  Tour 100% en Español
                </p>
              </div>
            </div>
            <div className="col s12 m7 offset-m1" style={{ padding: "0px" }}>
              <p className="titulo_7" style={{ marginBottom: "15px" }}>
                NO INCLUYE
              </p>
              <div className="caja_7">
                {window.screen.width <= 850 ? (
                  <img src={img9} alt="" className="img_tour_2" />
                ) : (
                  <img src={img13} alt="" className="img_tour_2" />
                )}
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <p
                    className="texto_1"
                    style={{
                      color: "#555555",
                      margin: "0px",
                      lineHeight: "40px",
                    }}
                  >
                    Completa tu aventura con el letrero de “Welcome to Las
                    Vegas” con costo extra.
                  </p>
                  {/* <p
                    className="titulo_3"
                    style={{ color: "#D83951", margin: "10px 0px" }}
                  >
                    Total tour con esta parada $50 USD
                  </p> */}
                </div>
              </div>
            </div>
          </div>
          <div className="caja_6">
            <p className="titulo_7" style={{ marginBottom: "0px" }}>
              INTINERARIO
            </p>
            <p className="texto_1" style={{ color: "#555555" }}>
              Nuestro <b>Tour a las 7 Montañas Mágicas</b> comienza en la puerta
              de tu hotel en Las Vegas, ahí nuestro guía de habla hispana pasará
              por ti en una cómoda van turística.
            </p>
          </div>
          <div className="box_intinerario">
            <div className="caja_inti">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <div className="linea_arriba_inv" />
                <div className="forma_3">1</div>
                <div className="linea_abajo" />
              </div>
              <div>
                <div className="box_intinerario_2">
                  <div className="texto_1">
                    {window.screen.width <= 850 ? (
                      <img
                        src={img3}
                        alt=""
                        className="img_tour_4"
                        style={{ float: "left" }}
                      />
                    ) : (
                      <img
                        src={img10}
                        alt=""
                        className="img_tour_4"
                        style={{ float: "left" }}
                      />
                    )}
                    <p className="texto_5">7 MONTAÑAS MAGICAS</p>
                    <p>
                      Salimos de tu hotel aproximadamente a las _____ y al
                      rededor de 30 minutos por la interestatal 15.
                    </p>
                    <p>
                      Al llegar podrás disfrutar de estas majestuosas rocas y
                      descubrirás que sus colores en medio del desierto, forman
                      un contraste increíble que garantiza unas fotografías
                      realmente increíbles, este es uno de los lugares más
                      instagrameables de toda Las Vegas.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="caja_inti">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <div className="linea_arriba" />
                <div className="forma_3">2</div>
                <div className="linea_abajo" />
              </div>
              <div>
                <div className="box_intinerario_2">
                  <div className="texto_1">
                    {window.screen.width <= 850 ? (
                      <img src={img4} alt="" className="img_tour_4" />
                    ) : (
                      <img src={img11} alt="" className="img_tour_4" />
                    )}
                    <p className="texto_5">LETRERO "LAS VEGAS SHOWGIRL"</p>
                    <p>
                      El letrero <b>Las Vegas Showgirls</b> es un nuevo letrero
                      de entrada que da la bienvenida a los viajeros al centro
                      de Las Vegas y brinda a los peatones la oportunidad de
                      tomarse una selfie.
                    </p>
                    <p>
                      También es parte del proyecto más grande de{" "}
                      <b>Main Street</b> de la Ciudad. Presenta las palabras
                      “Las Vegas” en letras que cambian de color, de 12 pies de
                      altura y está flanqueado por dos coristas de 26 pies de
                      altura. También cuenta con fichas de póquer y dados de
                      hormigón moldeados de gran tamaño y una gran rueda de
                      ruleta en el paisaje.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="caja_inti">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <div className="linea_arriba" />
                <div className="forma_3">3</div>
                <div className="linea_abajo_inv" />
              </div>
              <div>
                <div className="box_intinerario_2">
                  <div className="texto_1">
                    {window.screen.width <= 850 ? (
                      <img src={img6} alt="" className="img_tour_4" />
                    ) : (
                      <img src={img12} alt="" className="img_tour_4" />
                    )}
                    <p className="texto_5">LETRERO "ARCHWAY"</p>
                    <p>
                      Los arcos iluminados de 80 pies de altura ahora forman una
                      puerta de entrada al centro de Las Vegas. Están ubicados
                      en <b>Las Vegas Boulevard.</b> Es otra experiencia
                      exclusiva de Las Vegas para disfrutar durante tu visita.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="img_mont">
          <p className="titulo_1" style={{ fontSize: "53px" }}>
            ¿Te interesa este tour?
          </p>
          <a href="/contacto">
            <button type="" className="btn_3">
              <p>CONTÁCTANOS</p>
            </button>
          </a>
        </div>
      </div>
    </Fragment>
  );
};
export default MontaniasMagicas;

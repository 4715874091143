import React, { Fragment, Component } from "react";
import ReactDOM from "react-dom";
//import { firebaseConnect } from 'react-redux-firebase';
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import { connect } from "react-redux";
import Header from "../../../Components/SideNav";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
//import restAPI from '../../../helpers/RestAPI';
import PropTypes from "prop-types";

class NuevaImagen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file1: "",
      picture: null,
      nombre: "",
      prueba: "",
    };
  }

  handelUpload = (e) => {
    e.preventDefault();

    this.setState(
      {
        file1: e.target.files[0],
        nombre: this.props.producto.nombre,
      },
      () => {
        this.precarga();
      }
    );
  };

  precarga = () => {
    var file = this.state.file1,
      imageType = /image.*/;

    if (!file.type.match(imageType)) return;

    var reader = new FileReader();

    reader.onload = function (e) {
      var result = e.target.result;
      document.getElementById("imgSalida").src = result;
    };

    reader.readAsDataURL(file);
  };

  leerDato = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  subirDB = async (file) => {
    const { firestore } = this.props;

    if (file === "1") {
      if (this.state.file1 === "" || this.state.nombre === "") {
        Swal.fire(
          "Error",
          "No hay archivo seleccionado <br/>o <br/> Error en el nombre",
          "error"
        );
      } else {
        this.preloader();
        const { firebase } = this.props;
        var file1 = this.state.file1;
        const storageRef = firebase
          .storage()
          .ref(`/images/productos/${this.state.nombre}`);
        const task = storageRef.put(file1);

        task.on(
          "state_changed",
          (snapshot) => {
            let percentaje =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 500;
            this.setState({
              uploadValue: percentaje,
            });
          },
          (error) => {
            Swal.fire("Error", "Error al subir el archivo", "error");
          },
          () => {
            task.snapshot.ref.getDownloadURL().then((url) => {
              this.setState(
                {
                  uploadValue: 2000,
                  picture: url,
                },
                () => {
                  const producto = {
                    urlImagen: this.state.picture,
                  };
                  //guardado en la base de datos
                  firestore
                    .update(
                      {
                        collection: "producto",
                        doc: this.props.match.params.id,
                      },
                      producto
                    )
                    .then(() => this.editado());
                }
              );
            });
          }
        );
      }
    }
  };

  editado = () => {
    const { history } = this.props;
    Swal.fire("Inserción exitosa", "Imagen insertada correctamente", "success");
    history.push(`/admin/imagenes-productos/${this.props.producto.categoria}`);
  };

  preloader = () => {
    var preloader = (
      <div class="preloader-wrapper big active">
        <div class="spinner-layer spinner-red">
          <div class="circle-clipper left">
            <div class="circle"></div>
          </div>
          <div class="gap-patch">
            <div class="circle"></div>
          </div>
          <div class="circle-clipper right">
            <div class="circle"></div>
          </div>
        </div>
      </div>
    );
    ReactDOM.render(preloader, document.getElementById("preloader"));
  };

  render() {
    if (!this.props.producto) {
      return (
        <Fragment>
          <Header />
          <div
            className="container center-align"
            style={{ marginTop: "20%", marginBottom: "20%" }}
          >
            <div class="preloader-wrapper big active">
              <div class="spinner-layer spinner-red darken-4-only">
                <div class="circle-clipper left">
                  <div class="circle"></div>
                </div>
                <div class="gap-patch">
                  <div class="circle"></div>
                </div>
                <div class="circle-clipper right">
                  <div class="circle"></div>
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <Header mensaje="imagen a editar" />
          <div className="container center-align" style={{ marginTop: "50px" }}>
            <h2 id="font" style={{ marginBottom: "30px", marginTop: "0" }}>
              AGREGAR IMAGEN A PRODUCTO
            </h2>
            <div class="progress">
              <div
                class="determinate"
                style={{ width: this.state.uploadValue }}
              ></div>
            </div>
            <div
              id="preloader"
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "30px",
                justifyItems: "center",
              }}
            ></div>
            <div className="row">
              <div className="col s4">
                <div className="file-field input-field">
                  <div className="btn">
                    <span>File</span>
                    <input
                      onChange={this.handelUpload}
                      name="file"
                      type="file"
                    />
                  </div>
                  <div className="file-path-wrapper">
                    <input
                      className="file-path validate"
                      type="text"
                      placeholder="Seleccione una foto o arrastre una foto"
                    />
                  </div>
                </div>
                <div
                  className=" btn-outline-light center"
                  style={{ padding: "30px 0 30px 0" }}
                >
                  <div
                    id="font"
                    class="btn waves-effect waves-light"
                    onClick={() => this.subirDB("1")}
                    style={{ padding: "0 50px 0 50px", background: "#e57373 " }}
                  >
                    AGREGAR
                  </div>
                  <Link
                    to={`/admin/imagenes-productos/${this.props.producto.categoria}`}
                    id="font"
                    class="btn waves-effect waves-light"
                    style={{
                      padding: "0 50px 0 50px",
                      marginTop: "20px",
                      background: "#e57373 ",
                    }}
                  >
                    REGRESAR
                  </Link>
                </div>
              </div>
              <div className="col s8">
                <img
                  alt="imagen subida"
                  id="imgSalida"
                  width="100%"
                  src=""
                ></img>
              </div>
            </div>
          </div>
        </Fragment>
      );
    }
  }
}

NuevaImagen.protoType = {
  firestor: PropTypes.object.isRequired,
};

export default compose(
  firestoreConnect((props) => [
    {
      collection: "producto",
      storeAs: "producto",
      doc: props.match.params.id,
    },
  ]),
  connect(({ firestore: { ordered } }, props) => ({
    producto: ordered.producto && ordered.producto[0],
  }))
)(NuevaImagen);

import React, { Fragment } from "react";
import Mapa from "../Components/Mapa";

import icon1 from "../Images/iconos/Contactos/CI1.png";
import icon2 from "../Images/iconos/Contactos/CI2.png";
import icon3 from "../Images/iconos/Contactos/CI3.png";

const Contacto = () => {
  return (
    <Fragment>
      <div className="flex_contacto">
        <div className="box_contacto_1 container">
          <Mapa />
        </div>
        <div className="box_contacto_2">
          <div className="flex_padre_contactos">
            <div className="flex_contactos">
              <img src={icon1} alt="" className="icon_contactos" />
              <p className="texto_3">Llámanos o manda mensaje</p>
              <p className="texto_contacto">+17252676752</p>
            </div>
            <div className="flex_contactos">
              <img src={icon2} alt="" className="icon_contactos" />
              <p className="texto_3">Envíanos un mail</p>
              <p className="texto_contacto">tours@latinovegas.com</p>
            </div>
            <div className="flex_contactos">
              <img src={icon3} alt="" className="icon_contactos" />
              <p className="texto_3">Encuentranos en</p>
              <p className="texto_contacto">Las Vegas, Nevada.</p>
            </div>
          </div>
          <div className="center cont_3">
            <p className="titulo_3 center">PONTE EN CONTACTO CON NOSOTROS</p>
            <div style={{ padding: "0px 12%" }}>
              <p
                className="texto_4"
                style={{ color: "#555555", marginBottom: "35px" }}
              >
                Llena el formulario y nos pondremos en contacto contigo en
                breve.
              </p>
              <p className="texto_4" style={{ color: "#555555" }}>
                Hagamos de tu visita a Las Vegas, la mejor experiencia que
                puedas imaginar.
              </p>
            </div>
          </div>
          <div className="cont_4">
            <form>
              <div className="cont_formulario">
                <div className="flex_formulario_3">
                  <label className="texto_4" style={{ fontWeight: "bold" }}>
                    NOMBRE
                  </label>
                  <input
                    placeholder=""
                    style={{ paddingLeft: "10px" }}
                    id="nombre"
                    type="text"
                    class="validate require"
                  />
                </div>
                <div className="flex_formulario">
                  <label className="texto_4" style={{ fontWeight: "bold" }}>
                    CORREO
                  </label>
                  <input
                    placeholder=""
                    style={{ paddingLeft: "10px" }}
                    id="email"
                    type="email"
                    class="validate require"
                  />
                </div>
                <div className="flex_formulario">
                  <label className="texto_4" style={{ fontWeight: "bold" }}>
                    TELÉFONO CON CLAVE LADA
                  </label>
                  <input
                    placeholder=""
                    style={{ paddingLeft: "10px" }}
                    id="Telefono"
                    type="tel"
                    class="validate require"
                  />
                </div>
                <div className="flex_formulario_2">
                  <label className="texto_4" style={{ fontWeight: "bold" }}>
                    MENSAJE
                  </label>
                  <textarea
                    placeholder=""
                    style={{ paddingLeft: "10px" }}
                    id="mensaje"
                    class="materialize-textarea"
                  />
                </div>
              </div>
              <div className="caja_3">
                <button type="" className="btn_2">
                  <p>ENVIAR MENSAJE</p>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Contacto;

import React, {Component} from 'react';
import M from 'materialize-css';

import img1 from "../Images/footer/V2.png"

import Face from "../Images/footer/Face.png";
import Insta from "../Images/footer/Insta.png";
import Whats from "../Images/footer/Whats.png";

import icon1 from "../Images/footer/1.png"
import icon2 from "../Images/footer/2.png"
import icon3 from "../Images/footer/3.png"

class Footer extends Component {
    constructor (props) {
        super(props);
            this.state = {
        }
    }

    componentDidMount = () => {
        var elems = document.querySelectorAll('.tooltipped');
        M.Tooltip.init(elems); 
    }

    render (){
        return [
            <footer class="page-footer">
                <div>
                    <div class="flex_footer">
                        <div class="footer_1">
                            <p className='footer-texto'>
                                CONTENIDO
                            </p>
                            <ul style={{marginTop:"0px"}}>
                                <li><a href="/">INICIO</a></li>
                                <li><a href="/nosotros">NOSOTROS</a></li>
                                <li><a href="/tours">TOURS</a></li>
                                <li><a href="/contacto">CONTACTO</a></li>
                            </ul>
                        </div>
                        <div class="footer_2">
                            <div className="img_footer"/>
                            {/* <img src={img1} className="img_footer" alt=""/> */}
                            <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                                <a href="https://www.facebook.com/Latinovegastours-112662080307336/" target="blank">
                                    <img src={Face} className="footer_icon" alt=""/>
                                </a>
                                <a href="https://wa.me/17252676752?text=Hola, buen día, me gustaría recibir más información" target='blank'>
                                    <img src={Whats} className="footer_icon" alt=""/>
                                </a>
                                <a href="https://www.instagram.com/latinovegastours/" target="blank">
                                    <img src={Insta} className="footer_icon" alt=""/>
                                </a>
                                
                            </div>                       
                        </div>
                        <div className='footer_3'>
                            <p className='footer-texto'>
                                CONTACTO
                            </p>
                            <div>
                                <div className='flex_footer_2'>

                                    <img src={icon1} className="footer_icon_2" alt=""/>
                                    <p>
                                        +17252676752
                                    </p>
                                </div>
                                <div className='flex_footer_2'>
                                    <img src={icon2} className="footer_icon_2" alt=""/>
                                    <p>
                                        tours@latinovegas.com
                                    </p>
                                </div>
                                <div className='flex_footer_2'>
                                    <img src={icon3} className="footer_icon_2" alt=""/>
                                    <p>
                                        Las Vegas, Nevada
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="footer-copyright">
                    <div class="container center" style={{color:"#fff"}}>
                        Copyright © 2022 LatinoVegas. All rights reserved.
                        {/* <a class="grey-text text-lighten-4 right" href="#!">More Links</a> */}
                    </div>
                </div>
            </footer>
        ]
    }
}

export default Footer;
import React, { Fragment, Component } from "react";
import Swal from "sweetalert2";
import CryptoJS from "react-native-crypto-js";
import { firestoreConnect } from "react-redux-firebase";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: "",
      password: "",
    };
  }

  leerDato = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  iniciarSesion = (e) => {
    e.preventDefault();

    const sesion = { ...this.state };

    const { firestore } = this.props;
    const { history } = this.props;

    function consulta1() {
      return new Promise((resolve) => {
        firestore
          .collection("usuario")
          .where("correo", "==", sesion.user)
          .get()
          .then(function (querySnapshot) {
            if (querySnapshot.docs.length === 0) {
              resolve(1);
            } else {
              querySnapshot.forEach(function (doc) {
                resolve(doc);
              });
            }
          })
          .catch(function (error) {
            resolve(1);
          });
      });
    }

    async function guardarState1() {
      const acceso = consulta1();
      return acceso;
    }

    guardarState1().then((resultado) => {
      if (resultado === 1) {
        Swal.fire("Error!", "Opss, error al ingresar usuario!", "error");
      } else {
        let bytes = CryptoJS.AES.decrypt(resultado.data().password, "Y2Fhdg==");
        let originalText = bytes.toString(CryptoJS.enc.Utf8);
        if (originalText === sesion.password) {
          Swal.fire("Bien hecho!", "Bienvenido!", "success");
          let tipo = new Buffer(resultado.data().tipo).toString("base64");
          let tipoMostrar = new Buffer(resultado.data().tipoMostrar).toString(
            "base64"
          );
          let correo = new Buffer(resultado.data().correo).toString("base64");
          let nombre = new Buffer(resultado.data().nombre).toString("base64");
          sessionStorage.tipo = tipo;
          sessionStorage.tipoMostrar = tipoMostrar;
          sessionStorage.correo = correo;
          sessionStorage.nombre = nombre;
          sessionStorage.id = resultado.id;
          history.push("/admin/usuarios");
        } else {
          Swal.fire("Error!", "Opss, error al ingresar usuario!", "error");
        }
      }
    });
  };

  componentDidMount = () => {
    if (sessionStorage.error) {
      if (sessionStorage.error === "cerrada") {
        Swal.fire("Completado!", "Sesion cerrada correctamente!", "success");
        sessionStorage.clear();
      } else {
        Swal.fire(
          "Error!",
          "Opss, Usted debe iniciar sesion para acceder a la pagina!",
          "error"
        );
        sessionStorage.clear();
      }
    }
  };

  render() {
    return (
      <Fragment>
        <div
          style={{
            display: "flex",
            alignContent: "center",
            alignItems: "center",
            flexDirection: "column",
            marginTop: "5%",
          }}
        >
          <h1 id="font">INICIO DE SESION</h1>
          <div
            class="row"
            style={{
              display: "flex",
              alignContent: "center",
              alignItems: "center",
              borderRadius: "54px",
              height: "450px",
              width: "480px",
              border: "solid 1px #e57373 ",
            }}
          >
            <form class="col s12" onSubmit={this.iniciarSesion}>
              <div
                class="input-field"
                style={{ width: "300px", marginLeft: "70px" }}
              >
                <i class="fas fa-user prefix"></i>
                <input
                  id="user"
                  name="user"
                  onChange={this.leerDato}
                  value={this.state.user}
                  type="email"
                  class="validate"
                />
                <label for="user">Usuario</label>
              </div>
              <div
                class="input-field"
                style={{ width: "300px", marginLeft: "70px" }}
              >
                <i class="fas fa-unlock-alt prefix"></i>
                <input
                  id="password"
                  name="password"
                  onChange={this.leerDato}
                  defaultValue={this.state.password}
                  type="password"
                  class="validate"
                />
                <label for="password">Contraseña</label>
              </div>
              <div
                className=" btn-outline-light center"
                style={{ padding: "30px 0 30px 0" }}
              >
                <button
                  id="font-button"
                  class="btn waves-effect waves-light"
                  type="submit"
                  name="action"
                  style={{ padding: "0 50px 0 50px", background: "#e57373 " }}
                >
                  Inicia Sesion
                </button>
              </div>
            </form>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default firestoreConnect()(Login);

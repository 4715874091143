import L from 'leaflet';
import React, {Component} from 'react';


class Mapa extends Component{

    constructor(props){
        super(props);
        this.state = {
        }
    }

    componentDidMount = () => {
        //36.159333760877274, -115.18912191308428
        const position = [36.159333760877274, -115.18912191308428]
        const map = L.map('map').setView(position, 11)
        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
          attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
        }).addTo(map);
    
        // L.marker(position)
        //   .addTo(map)
        //   .bindPopup('Las Vegas')
        //   .openPopup();
    }

    render(){
        return [
            <div id="mapa" style={{zIndex:"1"}} >
                <div  id="map" className="map"  style={{height:'547px', width:'100%'}}></div>
            </div>
        ]
    }

}

export default Mapa;
import React, { Fragment, Component } from "react";
import ReactDOM from "react-dom";
import Card from "react-credit-cards";
import {
  formatCreditCardNumber,
  formatCVC,
  formatExpirationDate,
  //formatFormData
} from "../Helpers/utils";
import "react-credit-cards/es/styles-compiled.css";
import Conekta from "../Helpers/Conekta";
import restAPI from "../Helpers/RestAPI";
import carritoHelper from "../Helpers/Carrito";
import Swal from "sweetalert2";
import CryptoJS from "react-native-crypto-js";

import PropTypes from "prop-types";
import { firestoreConnect } from "react-redux-firebase";

class FormularioPago extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //informacion
      nombre: "",
      apellido: "",
      direccion: "",
      codigo_postal: "",
      ciudad: "",
      estado: "",
      pais: "",
      correo: "",
      telefono: "",
      envio: "0",
      fecha: new Date(),
      //envio
      envio: "Gratis",
      //datos de tarjeta
      tarjeta: "",
      titular: "",
      vencimiento: "",
      cvc: "",
      focused: "",
      //datos de tarjeta encriptados
      tarjeta_cr: "",
      titular_cr: "",
      vencimiento_cr: "",
      cvc_cr: "",
    };
  }

  /** diseño de tarjetas */
  handleCallback = ({ issuer }, isValid) => {
    if (isValid) {
      this.setState({
        issuer: issuer,
        issuer_cr: window.Conekta.b64.encode(issuer),
      });
    }
  };

  handleInputFocus = ({ target }) => {
    this.setState({
      focused: target.name,
    });
  };

  handleInputChange = ({ target }) => {
    var name = target.name + "_cr";
    if (target.name === "tarjeta") {
      target.value = formatCreditCardNumber(target.value);
    } else if (target.name === "vencimiento") {
      target.value = formatExpirationDate(target.value);
    } else if (target.name === "cvc") {
      target.value = formatCVC(target.value);
    }

    this.setState({
      [target.name]: target.value,
      [name]: window.Conekta.b64.encode(target.value),
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    //this.form.reset();

    if (window.Conekta !== undefined) {
      Conekta.initConekta();
    }

    if (this.state.titular === undefined || this.state.titular === "") {
      this.error("Faltan llenar datos!", "titular de la tarjeta");
    } else if (this.state.tarjeta === undefined || this.state.tarjeta === "") {
      this.error("Faltan llenar datos!", "numero de tarjeta");
    } else if (this.state.cvc === undefined || this.state.cvc === "") {
      this.error("Faltan llenar datos!", "CVC");
    } else if (
      this.state.vencimiento === undefined ||
      this.state.vencimiento === ""
    ) {
      this.error("Faltan llenar datos!", "vencimiento");
    } else {
      if (
        !Conekta.getCardBrand(window.Conekta.b64.decode(this.state.tarjeta_cr))
      ) {
        this.errorTarjeta(
          "Error en tipo de tarjeta!",
          "El numero de la tarjeta"
        );
      } else if (
        !Conekta.validateCardNumber(
          window.Conekta.b64.decode(this.state.tarjeta_cr)
        )
      ) {
        this.errorTarjeta("Datos erroneos!", "El numero de la tarjeta");
      } else if (
        !Conekta.validateCvc(window.Conekta.b64.decode(this.state.cvc_cr))
      ) {
        this.errorTarjeta("Datos erroneos!", "El codigo de seguridad");
      } else if (
        !Conekta.validateExpirationDate(
          window.Conekta.b64.decode(this.state.vencimiento_cr)
        )
      ) {
        this.errorTarjeta("Datos erroneos!", "El vencimiento");
      } else {
        this.comprar();
      }
    }
  };
  /** fin de diseño de tarjetas */

  comprar = () => {
    let fecha = window.Conekta.b64.decode(this.state.vencimiento_cr).split("/");

    //enviar capa de carga
    this.cambio();

    //actualizar estado de pago
    Conekta.tokenize(
      window.Conekta.b64.decode(this.state.tarjeta_cr),
      window.Conekta.b64.decode(this.state.titular_cr),
      fecha[0],
      "20" + fecha[1],
      window.Conekta.b64.decode(this.state.cvc_cr),
      this.successResponseHandler,
      this.errorResponseHandler
    );
  };

  successResponseHandler = async (token) => {
    const api = await restAPI.conectar(token, this.state);

    this.cambio();

    if (api.success) {
      this.agregado(api.data.id, api.type);
    } else {
      this.errorPago("Error al realizar pago", api.type);
    }
  };

  errorResponseHandler(error) {
    // Do something on error
    this.cambio();
    this.errorPago(
      "Error al realizar pago",
      "Tarjeta rechazada, intente otro metodo de pago"
    );
  }

  cambio = () => {
    const dato = document.getElementById("div-negro");
    if (dato.className === "div-negro-invisible") {
      dato.className = "div-negro";
    } else {
      dato.className = "div-negro-invisible";
    }
  };

  informacion = (e) => {
    e.preventDefault();
    const dato = document.getElementById("tarjetaPago");
    if (dato.className === "visible") {
      dato.className = "invisible";
    }
    var informacion = (
      <div>
        <nav className="nav-formulario">
          <div class="nav-wrapper nav-wrapper-formulario">
            <div class="col s12">
              <a href="/pagar" class="breadcrumb">
                Informacion
              </a>
            </div>
          </div>
        </nav>
        <div className="container">
          <h2>Pagar</h2>
          <div class="row">
            <h2>Direccion de envio</h2>
            <form class="col s12" onSubmit={this.continuar}>
              <div class="row">
                <div class="input-field col s6">
                  <input
                    onChange={this.leerDato}
                    defaultValue={this.state.nombre}
                    id="nombre"
                    name="nombre"
                    type="text"
                    class="validate"
                    required
                  />
                  <label className="active" for="nombre">
                    Nombre
                  </label>
                </div>
                <div class="input-field col s6">
                  <input
                    onChange={this.leerDato}
                    defaultValue={this.state.apellido}
                    id="apellido"
                    name="apellido"
                    type="text"
                    class="validate"
                    required
                  />
                  <label className="active" for="apellido">
                    Apellido
                  </label>
                </div>
              </div>
              <div class="row">
                <div class="input-field col s12">
                  <input
                    onChange={this.leerDato}
                    defaultValue={this.state.direccion}
                    id="direccion"
                    name="direccion"
                    type="text"
                    class="validate"
                    required
                  />
                  <label className="active" for="direccion">
                    Calle y numro (incluir numero interior o departamento)
                  </label>
                </div>
              </div>
              <div class="row">
                <div class="input-field col s6">
                  <input
                    onChange={this.leerDato}
                    defaultValue={this.state.codigo_postal}
                    id="codigo_postal"
                    name="codigo_postal"
                    type="text"
                    class="validate"
                    required
                  />
                  <label className="active" for="codigo_postal">
                    Codigo Postal
                  </label>
                </div>
                <div class="input-field col s6">
                  <input
                    onChange={this.leerDato}
                    defaultValue={this.state.ciudad}
                    id="ciudad"
                    name="ciudad"
                    type="text"
                    class="validate"
                    required
                  />
                  <label className="active" for="ciudad">
                    Ciudad
                  </label>
                </div>
              </div>
              <div class="row">
                <div class="input-field col s6">
                  <input
                    onChange={this.leerDato}
                    defaultValue={this.state.estado}
                    id="estado"
                    name="estado"
                    type="text"
                    class="validate"
                    required
                  />
                  <label className="active" for="estado">
                    Estado
                  </label>
                </div>
                <div class="input-field col s6">
                  <input
                    onChange={this.leerDato}
                    defaultValue={this.state.pais}
                    id="pais"
                    name="pais"
                    type="text"
                    class="validate"
                    required
                  />
                  <label className="active" for="pais">
                    Pais
                  </label>
                </div>
              </div>
              <h2>Información de contacto</h2>
              <div class="row">
                <div class="col s6 input-field inline">
                  <input
                    onChange={this.leerDato}
                    defaultValue={this.state.correo}
                    id="correo"
                    name="correo"
                    type="email"
                    class="validate"
                    required
                    placeholder="correo@correo.com"
                  />
                  <label className="active" for="correo">
                    Correo
                  </label>
                  <span
                    class="helper-text"
                    data-error="wrong"
                    data-success="right"
                  >
                    Ingresa un correo
                  </span>
                </div>
                <div class="input-field col s6">
                  <input
                    onChange={this.leerDato}
                    defaultValue={this.state.telefono}
                    id="telefono"
                    name="telefono"
                    type="tel"
                    class="validate"
                    required
                    pattern="[0-9]{10}"
                    placeholder="33-3333-3333"
                  />
                  <label className="active" for="telefono">
                    Telefono
                  </label>
                </div>
              </div>
              <button type="submit" style={{ margin: "10px" }} class="btn">
                Continuar
              </button>
            </form>
          </div>
        </div>
      </div>
    );
    ReactDOM.render(informacion, document.getElementById("informacion"));
  };

  envio = (e) => {
    const dato = document.getElementById("tarjetaPago");
    if (dato.className === "visible") {
      dato.className = "invisible";
    }
    e.preventDefault();
    var envio = (
      <div>
        <nav className="nav-formulario">
          <div class="nav-wrapper nav-wrapper-formulario">
            <div class="col s12">
              <a href="!#" onClick={this.informacion} class="breadcrumb">
                Información
              </a>
              <a href="!#" class="breadcrumb enlace_desactivado">
                Envío
              </a>
            </div>
          </div>
        </nav>
        <div className="container" style={{ marginTop: "100px" }}>
          <div style={{ border: "1px solid black", borderRadius: "10px" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                borderBottom: "1px solid black",
              }}
            >
              <p>Contacto</p>
              <p>
                {this.state.nombre} {this.state.apellido}
              </p>
              <p>
                <a href="!#" onClick={this.informacion}>
                  Cambiar
                </a>
              </p>
            </div>
            <div style={{ display: "flex", justifyContent: "space-around" }}>
              <p>Enviar a</p>
              <p>
                {this.state.direccion}, {this.state.estado}, {this.state.pais}
              </p>
              <p>
                <a href="!#" onClick={this.informacion}>
                  Cambiar
                </a>
              </p>
            </div>
          </div>
          <h3>Envios</h3>
          <form onSubmit={this.continuar_2}>
            <div style={{ border: "1px solid black", borderRadius: "10px" }}>
              {this.state.envio === "Gratis" ? (
                <div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                      textAlign: "left",
                      borderBottom: "1px solid black",
                    }}
                  >
                    <p>
                      <label>
                        <input
                          onChange={this.leerDato}
                          value="Gratis"
                          name="envio"
                          type="radio"
                          checked
                          required
                        />
                        <span>Envío Gratis</span>
                      </label>
                    </p>
                    <p>Gratis</p>
                  </div>
                  <div
                    style={{ display: "flex", justifyContent: "space-around" }}
                  >
                    <p>
                      <label>
                        <input
                          onChange={this.leerDato}
                          value="Express"
                          name="envio"
                          type="radio"
                          required
                        />
                        <span>Envío Express</span>
                      </label>
                    </p>
                    <p>160,00 $</p>
                  </div>
                </div>
              ) : (
                <div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                      textAlign: "left",
                      borderBottom: "1px solid black",
                    }}
                  >
                    <p>
                      <label>
                        <input
                          onChange={this.leerDato}
                          value="Gratis"
                          name="envio"
                          type="radio"
                          required
                        />
                        <span>Envío Gratis</span>
                      </label>
                    </p>
                    <p>Gratis</p>
                  </div>
                  <div
                    style={{ display: "flex", justifyContent: "space-around" }}
                  >
                    <p>
                      <label>
                        <input
                          onChange={this.leerDato}
                          value="Express"
                          name="envio"
                          type="radio"
                          checked
                          required
                        />
                        <span>Envío Express</span>
                      </label>
                    </p>
                    <p>160,00 $</p>
                  </div>
                </div>
              )}
            </div>
            <button type="submit" style={{ margin: "10px" }} class="btn">
              Continuar
            </button>
          </form>
        </div>
      </div>
    );
    ReactDOM.render(envio, document.getElementById("informacion"));
  };

  continuar = (e) => {
    e.preventDefault();
    if (this.state.nombre === undefined || this.state.nombre === "") {
      this.error("Faltan llenar datos!", "nombre");
    } else if (
      this.state.apellido === undefined ||
      this.state.apellido === ""
    ) {
      this.error("Faltan llenar datos!", "apellido");
    } else if (this.state.correo === undefined || this.state.correo === "") {
      this.error("Faltan llenar datos!", "correo");
    } else if (
      this.state.direccion === undefined ||
      this.state.direccion === ""
    ) {
      this.error("Faltan llenar datos!", "direccion");
    } else if (
      this.state.telefono === undefined ||
      this.state.telefono === ""
    ) {
      this.error("Faltan llenar datos!", "telefono");
    } else if (this.state.estado === undefined || this.state.estado === "") {
      this.error("Faltan llenar datos!", "estado");
    } else if (this.state.ciudad === undefined || this.state.ciudad === "") {
      this.error("Faltan llenar datos!", "ciudad");
    } else if (
      this.state.codigo_postal === undefined ||
      this.state.codigo_postal === ""
    ) {
      this.error("Faltan llenar datos!", "codigo postal");
    } else if (this.state.pais === undefined || this.state.pais === "") {
      this.error("Faltan llenar datos!", "pais");
    } else {
      this.envio(e);
      //this.pagar();
    }
  };

  continuar_2 = (e) => {
    e.preventDefault();
    const dato = document.getElementById("tarjetaPago");
    if (dato.className === "visible") {
      dato.className = "invisible";
    }
    if (this.state.envio === undefined || this.state.envio === "") {
      this.error("Faltan llenar datos!", "envío");
    } else {
      this.pagar();
    }
  };

  pagar = () => {
    const dato = document.getElementById("tarjetaPago");
    dato.className = "visible";
    var pagar = <div></div>;

    ReactDOM.render(pagar, document.getElementById("informacion"));
  };

  agregado = (id, type) => {
    const { history } = this.props;
    Swal.fire(
      "Compra exitosa!",
      "Su compra fue realizada exirtosamente <br> El identificador de su compra es: " +
        id +
        "<br>" +
        type,
      "success"
    ).then((result) => {
      history.push("/");
    });
  };

  error = (error, dato) => {
    Swal.fire(error, "Llena el dato de " + dato, "error");
  };

  errorTarjeta = (error, dato) => {
    Swal.fire(error, dato + " es erroneo", "error");
  };

  errorPago = (error, dato) => {
    Swal.fire(error, dato, "error");
  };

  leerConekta = (e) => {
    this.setState({
      [e.target.name]: window.Conekta.b64.encode(e.target.value),
    });
  };

  leerDato = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  componentDidMount = async () => {
    if (sessionStorage.data) {
      let bytes = CryptoJS.AES.decrypt(sessionStorage.data, "Y2Fhdg==");
      let originalText = bytes.toString(CryptoJS.enc.Utf8);
      if (originalText !== "") {
        this.setState(JSON.parse(originalText));
      } else {
        //history.push('/membresias');
      }
    } else {
      //extraer firestor de props
      const { firestore } = this.props;

      var res = await carritoHelper.obtener(firestore);
      this.setState({
        data: res.data,
        total: res.total,
      });
      //history.push('/membresias');
    }
  };

  render() {
    if (!this.state.data) {
      return (
        <Fragment>
          <div
            className="container center-align"
            style={{ marginTop: "20%", marginBottom: "20%" }}
          >
            <div class="preloader-wrapper big active">
              <div class="spinner-layer spinner-red darken-4-only">
                <div class="circle-clipper left">
                  <div class="circle"></div>
                </div>
                <div class="gap-patch">
                  <div class="circle"></div>
                </div>
                <div class="circle-clipper right">
                  <div class="circle"></div>
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <div class="div-negro-invisible" id="div-negro">
            <div>
              <div class="preloader-wrapper big active">
                <div class="spinner-layer spinner-red-only">
                  <div class="circle-clipper left">
                    <div class="circle"></div>
                  </div>
                  <div class="gap-patch">
                    <div class="circle"></div>
                  </div>
                  <div class="circle-clipper right">
                    <div class="circle"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col s12 m8 l8" style={{ padding: "0" }}>
              <div id="tarjetaPago" className="invisible">
                <nav className="nav-formulario">
                  <div class="nav-wrapper nav-wrapper-formulario">
                    <div class="col s12">
                      <a
                        href="!#"
                        onClick={this.informacion}
                        class="breadcrumb"
                      >
                        Informacion
                      </a>
                      <a href="!#" onClick={this.envio} class="breadcrumb">
                        Envío
                      </a>
                      <a href="!#" class="breadcrumb enlace_desactivado">
                        Pagar
                      </a>
                    </div>
                  </div>
                </nav>
                <div className="container" style={{ marginTop: "100px" }}>
                  <Card
                    number={this.state.tarjeta}
                    name={this.state.titular}
                    expiry={this.state.vencimiento}
                    cvc={this.state.cvc}
                    focused={this.state.focused}
                    callback={this.handleCallback}
                  />
                  <form className="container row" onSubmit={this.handleSubmit}>
                    <div className="input-field col s12">
                      <input
                        type="tel"
                        name="tarjeta"
                        id="tarjeta"
                        placeholder="E.g.: 49..., 51..., 36..., 37..."
                        pattern="[\d| ]{16,22}"
                        required
                        onChange={this.handleInputChange}
                        onFocus={this.handleInputFocus}
                      />
                      <label className="active" for="number">
                        Numero de la tarjeta
                      </label>
                    </div>
                    <div className="input-field col s12">
                      <input
                        type="text"
                        name="titular"
                        placeholder="JOHN SMITH"
                        required
                        onChange={this.handleInputChange}
                        onFocus={this.handleInputFocus}
                      />
                      <label className="active" for="number">
                        Nombre del titular
                      </label>
                    </div>
                    <div className="input-field col s6">
                      <input
                        type="tel"
                        name="vencimiento"
                        placeholder="10/20"
                        pattern="\d\d/\d\d"
                        required
                        onChange={this.handleInputChange}
                        onFocus={this.handleInputFocus}
                      />
                      <label className="active" for="number">
                        Vencimiento
                      </label>
                    </div>
                    <div className="input-field col s6">
                      <input
                        type="tel"
                        name="cvc"
                        placeholder="737"
                        pattern="\d{3,4}"
                        required
                        onChange={this.handleInputChange}
                        onFocus={this.handleInputFocus}
                      />
                      <label className="active" for="number">
                        CVC
                      </label>
                    </div>
                    <button className="btn">Pagar</button>
                  </form>
                </div>
              </div>
              <div id="informacion" style={{ marginBottom: "100px" }}>
                <nav className="nav-formulario">
                  <div class="nav-wrapper nav-wrapper-formulario">
                    <div class="col s12">
                      <a href="/pagar" class="breadcrumb">
                        Información
                      </a>
                    </div>
                  </div>
                </nav>
                <div className="container">
                  <h2>Pagar</h2>
                  <div class="row">
                    <h2>Direccion de envio</h2>
                    <form class="col s12" onSubmit={this.continuar}>
                      <div class="row">
                        <div class="input-field col s12 m6 l6">
                          <input
                            onChange={this.leerDato}
                            defaultValue={this.state.nombre}
                            id="nombre"
                            name="nombre"
                            type="text"
                            class="validate"
                            required
                          />
                          <label for="nombre">Nombre</label>
                        </div>
                        <div class="input-field col s12 m6 l6">
                          <input
                            onChange={this.leerDato}
                            defaultValue={this.state.apellido}
                            id="apellido"
                            name="apellido"
                            type="text"
                            class="validate"
                            required
                          />
                          <label for="apellido">Apellido</label>
                        </div>
                      </div>
                      <div class="row">
                        <div class="input-field col s12">
                          <input
                            onChange={this.leerDato}
                            defaultValue={this.state.direccion}
                            id="direccion"
                            name="direccion"
                            type="text"
                            class="validate"
                            required
                          />
                          <label for="direccion">
                            Calle y numro (Numero interior o departamento)
                          </label>
                        </div>
                      </div>
                      <div class="row">
                        <div class="input-field col s12 m6 l6">
                          <input
                            onChange={this.leerDato}
                            defaultValue={this.state.codigo_postal}
                            id="codigo_postal"
                            name="codigo_postal"
                            type="text"
                            class="validate"
                            required
                          />
                          <label for="codigo_postal">Codigo Postal</label>
                        </div>
                        <div class="input-field col s12 m6 l6">
                          <input
                            onChange={this.leerDato}
                            defaultValue={this.state.ciudad}
                            id="ciudad"
                            name="ciudad"
                            type="text"
                            class="validate"
                            required
                          />
                          <label for="ciudad">Ciudad</label>
                        </div>
                      </div>
                      <div class="row">
                        <div class="input-field col s12 m6 l6">
                          <input
                            onChange={this.leerDato}
                            defaultValue={this.state.estado}
                            id="estado"
                            name="estado"
                            type="text"
                            class="validate"
                            required
                          />
                          <label for="estado">Estado</label>
                        </div>
                        <div class="input-field col s12 m6 l6">
                          <input
                            onChange={this.leerDato}
                            defaultValue={this.state.pais}
                            id="pais"
                            name="pais"
                            type="text"
                            class="validate"
                            required
                          />
                          <label for="pais">Pais</label>
                        </div>
                      </div>
                      <h2>Información de contacto</h2>
                      <div class="row">
                        <div class="col s12 m6 l6 input-field inline">
                          <input
                            onChange={this.leerDato}
                            defaultValue={this.state.correo}
                            id="correo"
                            name="correo"
                            type="email"
                            class="validate"
                            required
                            placeholder="correo@correo.com"
                          />
                          <label for="correo" className="active">
                            Correo
                          </label>
                          <span
                            class="helper-text"
                            data-error="wrong"
                            data-success="right"
                          >
                            Ingresa un correo
                          </span>
                        </div>
                        <div class="input-field col s12 m6 l6">
                          <input
                            onChange={this.leerDato}
                            defaultValue={this.state.telefono}
                            id="telefono"
                            name="telefono"
                            type="tel"
                            class="validate"
                            required
                            pattern="[0-9]{10}"
                            placeholder="33-3333-3333"
                          />
                          <label for="telefono" className="active">
                            Telefono
                          </label>
                        </div>
                      </div>
                      <button
                        type="submit"
                        style={{ margin: "10px" }}
                        class="btn"
                      >
                        Continuar
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div className="col s12 m4 l4" style={{ marginTop: "25px" }}>
              <div className="col s12">
                {this.state.data.map((dato, index) => (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <img
                      src={dato.urlImagen}
                      alt=""
                      style={{ width: "100px" }}
                    />
                    <div style={{ marginLeft: "20px" }}>
                      <p>{dato.nombre}</p>
                      <p>{dato.precio}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div className="col s12 center">
                <h2>Total: {this.state.total}</h2>
              </div>
            </div>
          </div>
        </Fragment>
      );
    }
  }
}

FormularioPago.protoType = {
  firestor: PropTypes.object.isRequired,
};

export default firestoreConnect()(FormularioPago);

import React, { Fragment } from "react";

import Tour from "../Components/Tour";

import img2 from "../Images/escritorio/Tours/TE2.png";
import img3 from "../Images/escritorio/Tours/TE3.png";

const Tours = () => {
  return (
    <Fragment>
      <div className="img_tours">
        <p className="titulo_1">Tours</p>
      </div>
      <div className="cont_2">
        <Tour />
      </div>
      <p className="titulo_tours_1 center">Servicios personalizados</p>
      {/* otros tours */}
      <div className="flex_padre_tours">
        <div className="flex_tours">
          <p className="titulo_tours_2 center">TOURS PERSONALIZADOS</p>
          <img src={img2} alt="" style={{ width: "100%" }} />
          {/* <div className="img_tours_2" /> */}
          <div className="caja_2">
            <p>
              ¿Tienes un plan específico para tu visita o simplemente quieres
              vivir experiencias fuera de lo común?
            </p>
            <p>
              Ofrecemos tours totalmente personalizados, los cuales adaptamos a
              los lugares y actividades específicas que desees realizar para que
              tu experiencia sea aún más increíble.
            </p>
            <p>Contáctanos y comencemos a crear tu itinerario.</p>
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <a href="/contacto">
              <button type="" className="btn_tours">
                <p>SOLICITA INFORMACIÓN</p>
              </button>
            </a>
          </div>
        </div>
        <div className="flex_tours">
          <p className="titulo_tours_2 center">MAYORDOMIA</p>
          <div style={{ position: "relative" }}>
            <div
              className="forma_2"
              style={{ position: "absolute", right: "0" }}
            >
              VIP
            </div>
            <img src={img3} alt="" style={{ width: "100%" }} />
          </div>
          <div className="caja_2">
            <p>
              Uno de nuestros servicios más exclusivos es el de{" "}
              <b>MAYORDOMIA</b>.
            </p>
            <p>
              Consiste en estar a tu servicio desde que bajes del avión para
              hacer tu experiencia lo más inolvidable posible.
            </p>
            <p>
              24 horas al día te acompañamos dandote las mejores
              recomendaciones, desde cómo elegir el mejor restaurante para cenar
              hasta encargarnos de supervisar tu itinerario y transporte
              privado.
            </p>
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <a href="/contacto">
              <button type="" className="btn_tours">
                <p>SOLICITA INFORMACIÓN</p>
              </button>
            </a>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Tours;

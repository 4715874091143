import React, { Fragment, useState, useEffect } from "react";
import $ from "jquery";

import M from "materialize-css";

import icon1 from "../../Images/iconos/Tours/TI4.png";
import icon2 from "../../Images/iconos/Tours/TI5.png";
import icon3 from "../../Images/iconos/Tours/TI3.png";
import icon4 from "../../Images/iconos/Tours/TI2.png";

import img1 from "../../Images/escritorio/GranCanion/GE11.png";
import img2 from "../../Images/escritorio/GranCanion/GE3.png";
import img3 from "../../Images/escritorio/GranCanion/GE4.png";
import img4 from "../../Images/escritorio/GranCanion/GE5.png";
import img5 from "../../Images/escritorio/GranCanion/GE6.png";
import img6 from "../../Images/escritorio/GranCanion/GE7.png";
import img7 from "../../Images/escritorio/GranCanion/GE8.png";
import img8 from "../../Images/escritorio/GranCanion/GE9.png";
import img9 from "../../Images/escritorio/GranCanion/GE10.png";
import img10 from "../../Images/escritorio/GranCanion/GE12.png";
import img11 from "../../Images/escritorio/GranCanion/GE13.png";

import img12 from "../../Images/escritorio/GranCanion/recortes/GE4C.png";
import img13 from "../../Images/escritorio/GranCanion/recortes/GE7C.png";
import img14 from "../../Images/escritorio/GranCanion/recortes/GE10C.png";
import img15 from "../../Images/escritorio/GranCanion/recortes/GE11C.png";
import img16 from "../../Images/escritorio/GranCanion/recortes/GE12C.png";
import img17 from "../../Images/escritorio/GranCanion/recortes/GE13C.png";

const GranCanion = () => {
  const [imagenSeleccionada, setImagenSeleccionada] = useState(img1);
  const [imagenesMostrar, setImagenesMostrar] = useState(7);

  const imagenes = [
    img2,
    img3,
    img4,
    img5,
    img6,
    img7,
    img8,
    img9,
    img10,
    img11,
    img1,
  ];

  useEffect(() => {
    var elems = document.querySelectorAll(".carousel");
    M.Carousel.init(elems, {
      onCycleTo: (e) => {
        setImagenSeleccionada(imagenes[e.id]);
      },
    });
    if (window.screen.width < 851) {
      setImagenesMostrar(3);
    }
    $(window).scroll(function () {
      $("#efecto").each(function () {
        let efecto = $(this).offset().top;
        let topOfWindow = $(window).scrollTop();
        if (efecto < topOfWindow + 800) {
          $(this).addClass("fadeIn");
        }
      });
    });
  }, []);

  return (
    <Fragment>
      <div>
        <div className="cont_7">
          <p className="titulo_8 center">TOUR AL GRAN CAÑON</p>
          <div>
            <img src={imagenSeleccionada} alt="" style={{ width: "100%" }} />
          </div>

          <div class="carousel">
            <div id="0" class="carousel-item">
              <img
                src={img2}
                alt=""
                onClick={() => {
                  setImagenSeleccionada(img2);
                }}
                className="img_tour_3"
              />
            </div>
            <div id="1" class="carousel-item">
              <img
                src={img3}
                alt=""
                onClick={() => {
                  setImagenSeleccionada(img3);
                }}
                className="img_tour_3"
              />
            </div>
            <div id="2" class="carousel-item">
              <img
                src={img4}
                alt=""
                onClick={() => {
                  setImagenSeleccionada(img4);
                }}
                className="img_tour_3"
              />
            </div>
            <div id="3" class="carousel-item">
              <img
                src={img5}
                alt=""
                onClick={() => {
                  setImagenSeleccionada(img5);
                }}
                className="img_tour_3"
              />
            </div>
            <div id="4" class="carousel-item">
              <img
                src={img6}
                alt=""
                onClick={() => {
                  setImagenSeleccionada(img6);
                }}
                className="img_tour_3"
              />
            </div>
            <div id="5" class="carousel-item">
              <img
                src={img7}
                alt=""
                onClick={() => {
                  setImagenSeleccionada(img7);
                }}
                className="img_tour_3"
              />
            </div>
            <div id="6" class="carousel-item">
              <img
                src={img8}
                alt=""
                onClick={() => {
                  setImagenSeleccionada(img8);
                }}
                className="img_tour_3"
              />
            </div>
            <div id="7" class="carousel-item">
              <img
                src={img9}
                alt=""
                onClick={() => {
                  setImagenSeleccionada(img9);
                }}
                className="img_tour_3"
              />
            </div>
            <div id="8" class="carousel-item">
              <img
                src={img10}
                alt=""
                onClick={() => {
                  setImagenSeleccionada(img10);
                }}
                className="img_tour_3"
              />
            </div>
            <div id="9" class="carousel-item">
              <img
                src={img11}
                alt=""
                onClick={() => {
                  setImagenSeleccionada(img11);
                }}
                className="img_tour_3"
              />
            </div>
            <div id="10" class="carousel-item">
              <img
                src={img1}
                alt=""
                onClick={() => {
                  setImagenSeleccionada(img1);
                }}
                className="img_tour_3"
              />
            </div>
          </div>
        </div>
        <div className="caja_4">
          <div
            id="efecto"
            style={{
              display: "flex",
              justifyContent: "center",
              flexWrap: "wrap",
            }}
          >
            <div className="caja_5">
              <img
                src={icon1}
                alt=""
                style={{ width: "42px", height: "42px" }}
              />
              <p
                className="titulo_5"
                style={{ color: "#148D8D", margin: "0px" }}
              >
                DURACION
              </p>
              <p
                className="texto_6"
                style={{ margin: "0px", color: "#555555" }}
              >
                8 horas aprox.
              </p>
            </div>
            {/* <div className="caja_5">
              <img
                src={icon2}
                alt=""
                style={{ width: "28px", height: "43px" }}
              />
              <p
                className="titulo_5"
                style={{ color: "#D83951", margin: "0px" }}
              >
                PRECIO
              </p>
              <p
                className="texto_6"
                style={{
                  margin: "0px",
                  color: "#555555",
                  textDecoration: "line-through",
                }}
              >
                Desde $175 USD
              </p>
              <p
                className="texto_6"
                style={{ margin: "0px", color: "#D83951" }}
              >
                <b>OFERTA $155 USD</b>
              </p>
            </div> */}
            <div className="caja_5">
              <img
                src={icon3}
                alt=""
                style={{ width: "73px", height: "44px" }}
              />
              <p
                className="titulo_5"
                style={{ color: "#FDBB41", margin: "0px" }}
              >
                GRUPO
              </p>
              <p
                className="texto_6"
                style={{ margin: "0px", color: "#555555" }}
              >
                14 personas max.
              </p>
            </div>
          </div>
        </div>
        <div className="cont_5 texto_6" style={{ color: "#555555" }}>
          <p className="box_4">
            Tour al Grand Canyon West (Oeste) para pasar un día diferente, en
            medio de uno de los monumentos naturales más imponentes del mundo,
            como parte de tus vacaciones en Las Vegas.
          </p>
          <p className="box_4">
            Declarado Patrimonio de la Humanidad por la UNESCO en el año 1979,
            es un lugar que no querrás perderte. En el Gran Cañón, hogar de la
            reserva india de la Tribu Hualapai, tendrás la posibilidad de
            disfrutar de espectaculares vistas, realizar caminatas a través del
            hermoso paisaje y tomar las más increíbles fotografías.
          </p>
          <p className="box_4">
            Planifica tu primera aventura en el West Rim (Borde Oeste) por
            carretera en un Tour en español por Las Vegas, en el cual podrás
            viajar en una confortable Van.
          </p>

          <div className="row cont_6">
            <div className="col s12 m4" style={{ padding: "0px" }}>
              <p className="titulo_7" style={{ marginBottom: "15px" }}>
                INCLUYE
              </p>
              <div
                style={{
                  display: "flex",
                  justifyContent: "left",
                  alignItems: "center",
                }}
              >
                <img
                  src={icon4}
                  alt=""
                  style={{ width: "30px", height: "30px" }}
                />
                <p
                  className="texto_6"
                  style={{ color: "#555555", margin: "5px 10px" }}
                >
                  Salida desde tu hotel
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "left",
                  alignItems: "center",
                }}
              >
                <img
                  src={icon4}
                  alt=""
                  style={{ width: "30px", height: "30px" }}
                />
                <p
                  className="texto_6"
                  style={{ color: "#555555", margin: "5px 10px" }}
                >
                  Traslado ida y vuelta
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "left",
                  alignItems: "center",
                }}
              >
                <img
                  src={icon4}
                  alt=""
                  style={{ width: "30px", height: "30px" }}
                />
                <p
                  className="texto_6"
                  style={{ color: "#555555", margin: "5px 10px" }}
                >
                  Entrada al Gran Cañon
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "left",
                  alignItems: "center",
                }}
              >
                <img
                  src={icon4}
                  alt=""
                  style={{ width: "30px", height: "30px" }}
                />
                <p
                  className="texto_6"
                  style={{ color: "#555555", margin: "5px 10px" }}
                >
                  Agua
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "left",
                  alignItems: "center",
                }}
              >
                <img
                  src={icon4}
                  alt=""
                  style={{ width: "30px", height: "30px" }}
                />
                <p
                  className="texto_6"
                  style={{ color: "#555555", margin: "5px 10px" }}
                >
                  Comida en "Sky View Restaurant"
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "left",
                  alignItems: "center",
                }}
              >
                <img
                  src={icon4}
                  alt=""
                  style={{ width: "30px", height: "30px" }}
                />
                <p
                  className="texto_6"
                  style={{ color: "#555555", margin: "5px 10px" }}
                >
                  Guia 100% en Español
                </p>
              </div>
            </div>
            <div className="col s12 m7 offset-m1" style={{ padding: "0px" }}>
              <p className="titulo_7" style={{ marginBottom: "15px" }}>
                NO INCLUYE
              </p>
              <div className="caja_7">
                {window.screen.width <= 850 ? (
                  <img src={img1} alt="" className="img_tour_2" />
                ) : (
                  <img src={img15} alt="" className="img_tour_2" />
                )}
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <p
                    className="texto_6"
                    style={{
                      color: "#555555",
                      margin: "0px",
                      lineHeight: "40px",
                    }}
                  >
                    Entrada al “Skywalk ” (puente de cristal)
                    <br />
                    Agrega este destino a tu experiencia con costo extra.
                  </p>
                  {/* <p
                    className="titulo_3"
                    style={{ color: "#D83951", margin: "10px 0px" }}
                  >
                    Total tour con esta parada $175 USD
                  </p> */}
                </div>
              </div>
            </div>
          </div>

          <div className="caja_6">
            <p className="titulo_7" style={{ marginBottom: "0px" }}>
              INTINERARIO
            </p>
            <p className="texto_6" style={{ color: "#555555" }}>
              Nuestro <b>Tour al Gran Cañón</b> comienza en la puerta de tu
              hotel en Las Vegas, ahí nuestro guía de habla hispana pasará por
              ti en una cómoda van turística.
            </p>
          </div>
          <div className="box_intinerario">
            <div>
              <div className="caja_inti">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <div className="linea_arriba_inv" />
                  <div className="forma_3">1</div>
                  <div className="linea_abajo" />
                </div>
                <div>
                  <div className="box_intinerario_2">
                    <div className="texto_6">
                      {window.screen.width <= 850 ? (
                        <img
                          src={img10}
                          alt=""
                          className="img_tour_4"
                          style={{ float: "left" }}
                        />
                      ) : (
                        <img
                          src={img16}
                          alt=""
                          className="img_tour_4"
                          style={{ float: "left" }}
                        />
                      )}
                      <p className="texto_5">WELCOME TO LAS VEGAS</p>
                      <p>
                        Arrancamos entre 5:00 a 6:30 de la mañana y nuestra
                        primera parada es en el famoso letrero de Welcome to Las
                        Vegas, el cual es uno de los letreros más fotografiados
                        a nivel mundial.
                      </p>
                      <p>
                        El no tener una foto aquí es prácticamente como si nunca
                        hubieras venido, definitivamente no te lo puedes perder.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="caja_inti">
                <div className="caja_9">
                  <div className="linea_completa" />
                </div>
                <div className="caja_8">
                  <div className="texto_6">
                    <p>
                      Continuamos el recorrido pero no sin antes detenernos un
                      momento en la estación para adquirir snacks y bebidas para
                      recargar todas las pilas para después hacer otra parada en
                      el hermoso mirador del Río Colorado.
                    </p>
                    <p>
                      Atravesaremos varios poblados que parecieran sacados del
                      viejo oeste.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div className="caja_inti">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <div className="linea_arriba" />
                  <div className="forma_3">2</div>
                  <div className="linea_abajo" />
                </div>
                <div>
                  <div className="box_intinerario_2">
                    <div className="texto_6">
                      {window.screen.width <= 850 ? (
                        <img src={img9} alt="" className="img_tour_4" />
                      ) : (
                        <img src={img14} alt="" className="img_tour_4" />
                      )}
                      <p className="texto_5">JOSHUA TREE</p>
                      <p>
                        Nuestra segunda parada será en el desierto de Mojave
                        apreciando el bosque de Joshua Tree, este lugar es la
                        ÚNICA parte en el mundo en donde crecen estos árboles
                        tan peculiares.
                      </p>
                      <p>
                        Algunos de ellos llegan a tener hasta 800 años de vida,
                        definitivamente es un lugar con una vibra mágica.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="caja_inti">
                <div className="caja_9">
                  <div className="linea_completa" />
                </div>
                <div className="caja_8">
                  <div className="texto_6">
                    <p>
                      Al llegar al <b>Gran Cañón</b>, comenzamos nuestro
                      recorrido por los diferentes miradores de la
                      <span style={{ color: "#D83951" }}>
                        <b> Comunidad Indígena de Hualapai.</b>
                      </span>
                    </p>
                    <p>
                      Los Hualapai son una tribu de indígenas que habitan en las
                      montañas del noroeste de Arizona. El nombre se deriva de
                      “hwa:l”, que quiere decir pino ponderosa; “hualapai”
                      significa “gente del pino ponderosa”.
                    </p>
                    <p>Aquí exploraremos varios puntos:</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="caja_inti">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <div className="linea_arriba" />
                <div className="forma_3">3</div>
                <div className="linea_abajo" />
              </div>
              <div>
                <div className="box_intinerario_2">
                  <div className="texto_6">
                    {window.screen.width <= 850 ? (
                      <img src={img3} alt="" className="img_tour_4" />
                    ) : (
                      <img src={img12} alt="" className="img_tour_4" />
                    )}
                    <p className="texto_5" style={{ marginTop: "25px" }}>
                      EAGLE POINT
                    </p>
                    <p>
                      “El Punto del Águila” conocido como Eagle Point, este
                      lugar es sagrado para los nativos, ya que ellos
                      consideraban al águila como el animal que llevaba sus
                      peticiones al creador.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="caja_inti">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <div className="linea_arriba" />
                <div
                  className="forma_3"
                  style={{ border: "solid 3px #FDBB41", color: "#FDBB41" }}
                >
                  4
                </div>
                <div className="linea_abajo" />
              </div>
              <div>
                <div className="box_intinerario_2">
                  <div className="texto_6">
                    <div
                      className="forma_2"
                      style={{
                        width: "127px",
                        height: "42px",
                        fontSize: "22px",
                        position: "relative",
                        top: "42px",
                      }}
                    >
                      Opcional
                    </div>
                    {window.screen.width <= 850 ? (
                      <img src={img1} alt="" className="img_tour_4" />
                    ) : (
                      <img src={img15} alt="" className="img_tour_4" />
                    )}
                    <p className="texto_5" style={{ color: "#FDBB41" }}>
                      SKYWALK
                    </p>
                    <p>
                      Visitaremos el increíble puente de cristal Skywalk una
                      estructura que costó más de $30 millones de dólares
                      realizar.
                    </p>
                    <p>
                      Sin duda estar sobre un puente transparente a 1, 200
                      metros de altura pondrá a prueba tus miedos y te sacará de
                      tu zona de confort, ofreciendo un panorama espectacular.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="caja_inti">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <div className="linea_arriba" />
                <div className="forma_3">5</div>
                <div className="linea_abajo" />
              </div>
              <div>
                <div className="box_intinerario_2">
                  <div className="texto_6">
                    {window.screen.width <= 850 ? (
                      <img src={img11} alt="" className="img_tour_4" />
                    ) : (
                      <img src={img17} alt="" className="img_tour_4" />
                    )}
                    <p className="texto_5" style={{ marginTop: "40px" }}>
                      SKYVIEW RESTAURANT
                    </p>
                    <p>
                      Subiremos al restaurante <b>SkyView Restaurant</b>, el
                      cual es reconocido por tener una de las mejores vistas a
                      nivel mundial. Ahí disfrutaremos de exquisitos platillos
                      antes de continuar nuestra aventura.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div className="caja_inti">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <div className="linea_arriba" />
                  <div className="forma_3">6</div>
                  <div className="linea_abajo_inv" />
                </div>
                <div>
                  <div className="box_intinerario_2">
                    <div className="texto_1">
                      {window.screen.width <= 850 ? (
                        <img src={img6} alt="" className="img_tour_4" />
                      ) : (
                        <img src={img13} alt="" className="img_tour_4" />
                      )}
                      <p className="texto_5" style={{ marginTop: "40px" }}>
                        GUANO POINT
                      </p>
                      <p>
                        Aquí encontraremos <b>LA MEJOR VISTA</b> del Gran Cañón,
                        no pararás de admirar y fotografiar el increíble
                        paisaje.
                      </p>
                      <p>
                        Quedándonos con esta bella imagen, damos por finalizado
                        el tour e iniciamos el regreso a la capital del
                        entretenimiento, haciendo una última parada sorpresa
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="caja_inti">
                <div className="caja_9" />
                <div className="caja_8">
                  <div
                    className="box_intinerario_2"
                    style={{ marginTop: "0px" }}
                  >
                    <div className="texto_1">
                      <p>
                        Finalmente llegamos aproximadamente entre 3:30 pm - 4:00
                        pm (hora de Las Vegas).
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="img_gran">
          <p className="titulo_1" style={{ fontSize: "53px" }}>
            ¿Te interesa este tour?
          </p>
          <a href="/contacto">
            <button type="" className="btn_3">
              <p>CONTÁCTANOS</p>
            </button>
          </a>
        </div>
      </div>
    </Fragment>
  );
};
export default GranCanion;

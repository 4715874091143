import React, { Fragment, useEffect } from "react";
import $ from "jquery";

import Tour from "../Components/Tour";

import img1 from "../Images/escritorio/Index/IE2.png";

import img2 from "../Images/escritorio/Index/Gal/1.png";
import img3 from "../Images/escritorio/Index/Gal/2.png";
import img4 from "../Images/escritorio/Index/Gal/3.png";
import img5 from "../Images/escritorio/Index/Gal/4.png";
import img6 from "../Images/escritorio/Index/Gal/5.png";
import img7 from "../Images/escritorio/Index/Gal/6.png";
import img8 from "../Images/escritorio/Index/Gal/7.png";
import img9 from "../Images/escritorio/Index/Gal/8.png";
import img10 from "../Images/escritorio/Index/Gal/9.png";

import iconII1 from "../Images/iconos/Index/II1.png";
import iconII2 from "../Images/iconos/Index/II2.png";
import iconII3 from "../Images/iconos/Index/II3.png";

import icon1 from "../Images/iconos/Index/1.png";
import icon2 from "../Images/iconos/Index/2.png";
import icon3 from "../Images/iconos/Index/3.png";

const Index = () => {
  useEffect(() => {
    $(window).scroll(function () {
      $("#efecto").each(function () {
        let efecto = $(this).offset().top;
        let topOfWindow = $(window).scrollTop();
        if (efecto < topOfWindow + 800) {
          $(this).addClass("fadeIn");
        }
      });
    });
  }, []);

  return (
    <Fragment>
      <div>
        <div className="img_index_1">
          <p className="titulo_10 center">
            Adiós a la realidad, bienvenido a Las Vegas.
          </p>
          <p className="texto_6" style={{ color: "#fff" }}>
            Haz de tu visita a las vegas una experiencia realmente inolvidable
            con recorridos guiados en tu propio idioma, porque en inglés hay
            muchos, pero en español ninguno mejor.
          </p>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            <a href="/tours">
              <div className="btn_5" style={{ margin: "10px 20px" }}>
                <p>VER TOURS</p>
              </div>
            </a>
            <a href="/nosotros">
              <div className="btn_6" style={{ margin: "10px 20px" }}>
                <p>CONÓCENOS</p>
              </div>
            </a>
          </div>
        </div>

        <div className="container">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexWrap: "wrap",
              marginTop: "50px",
            }}
          >
            <div className="flex_1">
              <div className="texto_6">
                <p className="titulo_7" style={{ color: "#0E2C40" }}>
                  ¿Por qué visitar Las Vegas?
                </p>
                <p>
                  Las Vegas es la capital mundial del entretenimiento, un
                  verdadero parque de atracciones para adultos que no te
                  defraudará.
                </p>
                <p>
                  Con sus sorprendentes hoteles temáticos y sus increíbles
                  espectáculos te sentirás como si estuvieras de vacaciones en
                  un mundo paralelo.
                </p>
              </div>
            </div>
            <div className="flex_2">
              <img src={img1} alt="" className="img_index_2" />
            </div>
          </div>
          <p className="center titulo_2">
            Aquí empieza tu historia para conocer <br />
            la ciudad del pecado.
          </p>
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            <img
              src={icon1}
              alt=""
              style={{ width: "161px", height: "161px", margin: "20px" }}
            />
            <img
              src={icon2}
              alt=""
              style={{ width: "211px", height: "126px", margin: "20px" }}
            />
            <img
              src={icon3}
              alt=""
              style={{ width: "147px", height: "144px", margin: "20px" }}
            />
          </div>
        </div>

        <div className="img_index_3" style={{ marginBottom: "20px" }}>
          <div className="cont_10">
            <div>
              <p className="titulo_9">
                Luces, cámara...
                <br />
                <b>y Las Vegas</b> en un tour
              </p>
            </div>
            <div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <a href="/tours">
                  <div className="btn_4">
                    <p>VER TODOS LOS TOURS</p>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="cont_9">
          <p className="texto_7 center">
            Ofrecemos la mejor guía para optimizar tu tiempo y dinero,
            garantizándote una experiencia completa y satisfactoria.
          </p>
        </div>

        <div
          id="efecto"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          <div className="box_index">
            <img
              src={iconII3}
              alt=""
              style={{ width: "119px", height: "91px" }}
            />
            <p className="texto_2" style={{ margin: "10px" }}>
              En tu hotel
            </p>
            <p
              className="texto_4"
              style={{ color: "#777777", marginTop: "10px" }}
            >
              La aventura inicia y termina en la puerta <br />
              de tu hotel. No necesitas trasladarte a <br />
              ningún punto por tu cuenta.
            </p>
          </div>
          <div className="box_index">
            <img
              src={iconII1}
              alt=""
              style={{ width: "95px", height: "91px" }}
            />
            <p className="texto_2" style={{ margin: "10px" }}>
              100% en español
            </p>
            <p
              className="texto_4"
              style={{ color: "#777777", marginTop: "10px" }}
            >
              Nada mejora tanto una experiencia <br />
              como el poder disfrutar otro país en <br />
              tu propio idioma y con ese toque latino.
            </p>
          </div>
          <div className="box_index">
            <img
              src={iconII2}
              alt=""
              style={{ width: "100px", height: "101px" }}
            />
            <p className="texto_2" style={{ margin: "10px" }}>
              Aventuras garantizadas
            </p>
            <p
              className="texto_4"
              style={{ color: "#777777", marginTop: "10px" }}
            >
              Combinamos la exclusividad y la diversión para crear las mejores
              aventuras en los tours de mejor calidad al mejor precio.
            </p>
          </div>
        </div>

        <div className="texto_7 center container">
          <p className="caja_10">
            Además tenemos servicios <b>VIP exclusivos.</b>
          </p>
          <p className="caja_10">
            Nuestro servicio de Mayordomía sin duda hará tu estancia aún más
            placentera, fácil y agradable.
          </p>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <a href="/contacto">
              <div
                className="btn_2"
                style={{ width: "285px", margin: "40px 0px" }}
              >
                <p>SOLÍCITA MÁS INFORMACIÓN</p>
              </div>
            </a>
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div className="forma_4">TOURS</div>
        </div>
        <div className="cont_2">
          <Tour />
        </div>
        <div className="box_5">
          <a href="/tours">
            <div className="btn_7">
              <p>VER TODOS</p>
            </div>
          </a>
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div alt="" className="icon_index_1" />
        </div>

        <div className="texto_7 center container">
          <p className="caja_10">
            Descubre los mejores lugares para visitar en Las Vegas.
          </p>
          <p className="caja_10">
            Conoce los hoteles más famosos y las atracciones más importantes en
            la ciudad del juego.
          </p>
        </div>

        <div
          className="cont_galeria"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          <div className="box_6">
            <img src={img2} alt="" className="img_index_4" />
          </div>
          <div className="box_6">
            <img src={img3} alt="" className="img_index_4" />
          </div>
          <div className="box_6">
            <img src={img4} alt="" className="img_index_4" />
          </div>
          <div className="box_6">
            <img src={img5} alt="" className="img_index_4" />
          </div>
          <div className="box_6">
            <img src={img6} alt="" className="img_index_4" />
          </div>
          <div className="box_6">
            <img src={img7} alt="" className="img_index_4" />
          </div>
          <div className="box_6">
            <img src={img8} alt="" className="img_index_4" />
          </div>
          <div className="box_6">
            <img src={img9} alt="" className="img_index_4" />
          </div>
          <div className="box_6">
            <img src={img10} alt="" className="img_index_4" />
          </div>
        </div>
        <div className="box_5" style={{ marginBottom: "100px" }}>
          <a href="/tours">
            <button type="" className="btn_2" style={{ margin: "0px" }}>
              <p>COTIZA TU TOUR</p>
            </button>
          </a>
        </div>
      </div>
    </Fragment>
  );
};

export default Index;

import React, { Fragment, Component } from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import ReactHtmlParser from "react-html-parser";
import carritoHelper from "../Helpers/Carrito";

class Catalogo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cantidad: 1,
    };
  }

  agregarCarrito = () => {
    //extraer firestor de props
    const { firestore } = this.props;

    //guardado en la base de datos
    var nuevo = {
      id: this.props.producto.id,
      cantidad: parseInt(this.state.cantidad),
      nombre: this.props.producto.nombre,
    };

    carritoHelper.agregar("carrito", firestore, nuevo);
  };

  leerDato = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  render() {
    if (!this.props.producto) {
      return (
        <Fragment>
          <div
            className="container center-align"
            style={{ marginTop: "20%", marginBottom: "20%" }}
          >
            <div class="preloader-wrapper big active">
              <div class="spinner-layer spinner-red darken-4-only">
                <div class="circle-clipper left">
                  <div class="circle"></div>
                </div>
                <div class="gap-patch">
                  <div class="circle"></div>
                </div>
                <div class="circle-clipper right">
                  <div class="circle"></div>
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <div
            className="container"
            style={{ marginBottom: "100px", marginTop: "50px" }}
          >
            <p className="titulo_5 texto_absolute">
              {this.props.producto.nombre}
            </p>
            <img
              src={this.props.producto.urlImagen}
              alt=""
              style={{ width: "50%" }}
            />
          </div>
          <div className="container center">
            <p>{ReactHtmlParser(this.props.producto.precio)}</p>
            <p>Información básica</p>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <p>Especificacion: {this.props.producto.especificacion}</p>
            </div>
            <button className="btn" onClick={this.agregarCarrito}>
              Agregar a carrito
            </button>
            <div class="input-field">
              <input
                onChange={this.leerDato}
                id="cantidad"
                name="cantidad"
                type="text"
                class="validate"
                required
              />
              <label for="cantidad">Cantidad</label>
            </div>
          </div>
        </Fragment>
      );
    }
  }
}

Catalogo.protoType = {
  firestor: PropTypes.object.isRequired,
};

export default compose(
  firestoreConnect((props) => [
    {
      collection: "producto",
      doc: props.match.params.id,
    },
  ]),
  connect(({ firestore: { ordered } }, props) => ({
    producto: ordered.producto && ordered.producto[0],
  }))
)(Catalogo);

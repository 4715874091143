import React, {Fragment}  from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter, Route} from 'react-router-dom';
import store from './store';
import { Provider } from 'react-redux';

import './index.css';
import './animation.css';

// *** componentes ***  //
import NavBar from './Components/Header';
import Footer from './Components/Footer';
import Redes from './Components/Redes';

// *** vistas ***  //
import Index from './Views/Index';
import Nosotros from './Views/Nosotros';
import Tours from './Views/Tours';
import GranCanion from './Views/Tours/GranCanion';
import MontaniasMagicas from './Views/Tours/MontaniasMagicas';
import NochesVegas from './Views/Tours/NocheVegas';

import Servicios from './Views/Servicios';
import Contacto from './Views/Contacto';
import Catalogo from './Views/Catalogo';
import Producto from './Views/Producto';
import FormularioPago from './Views/FormularioPago';
import Carrito from './Views/Carrito';


// *** administrador *** //
import login from './Views/administrador/Login';
//usuarios
import usuarios from './Views/administrador/Usuarios/Usuarios';
import nuevoUsuario from './Views/administrador/Usuarios/NuevoUsuario';
import editarUsuario from './Views/administrador/Usuarios/EditarUsuario';
//categorias
import categorias from './Views/administrador/Categorias/Categorias';
import nuevoCategoria from './Views/administrador/Categorias/NuevaCategoria';
import editarCategoria from './Views/administrador/Categorias/EditarCategoria';
//sub-categorias
import subcategorias from './Views/administrador/SubCategorias/subCategorias';
import nuevoSubCategoria from './Views/administrador/SubCategorias/nuevaSubCategoria';
import editarSubCategoria from './Views/administrador/SubCategorias/editarSubCategoria';
//productos
import productos from './Views/administrador/Productos/Productos';
import nuevoProducto from './Views/administrador/Productos/NuevoProducto';
import editarProducto from './Views/administrador/Productos/EditarProducto';
//imagenes
import imagenesCategorias from './Views/administrador/Imagenes/Categorias';
import imagenesProductos from './Views/administrador/Imagenes/Imagenes';
import nuevaImagenProducto from './Views/administrador/Imagenes/NuevaImagen';
import editarImagenProducto from './Views/administrador/Imagenes/EditarImagen';
//ventas
import ventas from './Views/administrador/Ventas/ventas';


const App = () =>(
    <Provider store={store}>

        <BrowserRouter>
            <Fragment>
                <Redes />
                {window.location.pathname.split('/')[1] === 'admin' ? (
                    <span></span>
                ) : (
                    <NavBar />
                )}
                
                {/* vistas */}
                <Route exact path = "/" component = {Index} />
                <Route  path = "/nosotros" component = {Nosotros} />
                <Route  path = "/tours" component = {Tours} />
                <Route  path = "/tours-gran-canion" component = {GranCanion} />
                <Route  path = "/tours-7-montanias-magicas" component = {MontaniasMagicas} />
                <Route  path = "/tours-noche-por-las-vegas" component = {NochesVegas} />

                

                <Route  path = "/servicios" component = {Servicios} />
                <Route  path = "/contacto" component = {Contacto} />
                <Route  path = "/catalogo" component = {Catalogo} />
                <Route  path = "/producto/:id" component = {Producto} />
                <Route  path = "/carrito" component = {Carrito} />
                <Route  path = "/pagar" component = {FormularioPago} />
                {/* fin - vistas */}
                {/* administrador */}
                <   Route  path = "/admin/login" component = {login} />
                        {/* usuario */}
                        <Route  path = "/admin/usuarios" component = {usuarios} />
                        <Route  path = "/admin/nuevo-usuario" component = {nuevoUsuario} />
                        <Route  path = "/admin/editar-usuario/:id" component = {editarUsuario} />
                        {/* fin - usuario */}
                        {/* categorias */}
                        <Route  path = "/admin/categorias" component = {categorias} />
                        <Route  path = "/admin/nueva-categoria" component = {nuevoCategoria} />
                        <Route  path = "/admin/editar-categoria/:id" component = {editarCategoria} />
                        {/* fin - categorias */}
                        {/* productos */}
                        <Route  path = "/admin/productos/:id" component = {productos} />
                        <Route  path = "/admin/nuevo-producto/:id" component = {nuevoProducto} />
                        <Route  path = "/admin/editar-producto/:id" component = {editarProducto} />
                        {/* fin - productos */}
                        {/* imagenes */}
                        <Route  path = "/admin/imagenes-categorias" component = {imagenesCategorias} />
                        <Route  path = "/admin/imagenes-productos/:id" component = {imagenesProductos} />
                        <Route  path = "/admin/nueva-imagen-producto/:id" component = {nuevaImagenProducto} />
                        <Route  path = "/admin/editar-imagen-producto/:id" component = {editarImagenProducto} />
                        {/* fin - imagenes */}
                    {/* fin - administrador */}
                {window.location.pathname.split('/')[1] === 'admin' ? (
                    <span></span>
                ) : (
                    <Footer />
                )}
            </Fragment>
        </BrowserRouter>
    </Provider>
);

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();

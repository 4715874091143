import React, { Component } from "react";
import M from "materialize-css";
import faceredes from "../Images/face.png";
import whatsredes from "../Images/whats.png";
import insta from "../Images/insta.png";

class Redes extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => {
    var elems = document.querySelectorAll(".fixed-action-btn");
    M.FloatingActionButton.init(elems);
  };

  render() {
    return [
      <div
        style={{ marginBottom: "20px" }}
        class="fixed-action-btn"
        id="boton-redes"
      >
        <a
          href="#!"
          className="btn-floating btn-large darken-3 pulse"
          style={{ background: "#D83951" }}
        >
          <i class="large material-icons" translate="no">
            call
          </i>
        </a>
        <ul>
          <li>
            <a
              rel="noopener noreferrer"
              target="_blank"
              class="btn-floating blue"
              href="https://www.facebook.com/Latinovegastours-112662080307336/"
            >
              <img
                id="imagenes"
                alt="imagen face"
                src={faceredes}
                style={{ width: "100%" }}
              />
            </a>
          </li>
          <li>
            <a
              rel="noopener noreferrer"
              target="_blank"
              class="btn-floating blue"
              href="https://www.instagram.com/latinovegastours/"
            >
              <img
                id="imagenes"
                alt="imagen face"
                src={insta}
                style={{ width: "100%" }}
              />
            </a>
          </li>
          <li>
            <a
              rel="noopener noreferrer"
              target="_blank"
              class="btn-floating green"
              href="https://wa.me/17252676752?text=Hola, buen día, me gustaría recibir más información"
            >
              <img
                id="imagenes"
                alt="imagen whats"
                src={whatsredes}
                style={{ width: "100%" }}
              />
            </a>
          </li>
        </ul>
      </div>,
    ];
  }
}

export default Redes;

import React, { Fragment, useState, useEffect } from "react";
import $ from "jquery";

import M from "materialize-css";

import icon1 from "../../Images/iconos/Tours/TI4.png";
import icon2 from "../../Images/iconos/Tours/TI5.png";
import icon3 from "../../Images/iconos/Tours/TI3.png";
import icon4 from "../../Images/iconos/Tours/TI2.png";

import img1 from "../../Images/escritorio/Vegas/VE1.png";
import img2 from "../../Images/escritorio/Vegas/VE2.png";
import img3 from "../../Images/escritorio/Vegas/VE3.png";
import img4 from "../../Images/escritorio/Vegas/VE4.png";
import img5 from "../../Images/escritorio/Vegas/VE5.jpg";
import img6 from "../../Images/escritorio/Vegas/VE6.jpg";
import img7 from "../../Images/escritorio/Vegas/VE7.jpg";
import img8 from "../../Images/escritorio/Vegas/VE8.png";
import img9 from "../../Images/escritorio/Vegas/VE9.jpg";
import img10 from "../../Images/escritorio/Vegas/VE10.jpg";
import img11 from "../../Images/escritorio/Vegas/VE11.png";
import img12 from "../../Images/escritorio/Vegas/VE12.jpg";
import img13 from "../../Images/escritorio/Vegas/VE13.png";

import img14 from "../../Images/escritorio/Vegas/recorte/VE4C.png";
import img15 from "../../Images/escritorio/Vegas/recorte/VE9C.jpg";
import img16 from "../../Images/escritorio/Vegas/recorte/VE10C.jpg";
import img17 from "../../Images/escritorio/Vegas/recorte/VE8C.png";
import img18 from "../../Images/escritorio/Vegas/recorte/VE7C.jpg";

const NochesVegas = () => {
  const [imagenSeleccionada, setImagenSeleccionada] = useState(img1);
  const [imagenesMostrar, setImagenesMostrar] = useState(7);

  const imagenes = [
    img1,
    img2,
    img3,
    img4,
    img5,
    img6,
    img7,
    img8,
    img9,
    img10,
    img11,
    img12,
    img13,
  ];

  useEffect(() => {
    var elems = document.querySelectorAll(".carousel");
    M.Carousel.init(elems, {
      onCycleTo: (e) => {
        setImagenSeleccionada(imagenes[e.id]);
      },
    });
    if (window.screen.width < 851) {
      setImagenesMostrar(3);
    }
    $(window).scroll(function () {
      $("#efecto").each(function () {
        let efecto = $(this).offset().top;
        let topOfWindow = $(window).scrollTop();
        if (efecto < topOfWindow + 800) {
          $(this).addClass("fadeIn");
        }
      });
    });
  }, []);

  return (
    <Fragment>
      <div>
        <div className="cont_7">
          <p className="titulo_8 center">DE NOCHE POR LAS VEGAS</p>
          <div>
            <img src={imagenSeleccionada} alt="" style={{ width: "100%" }} />
          </div>

          <div class="carousel">
            <div id="0" class="carousel-item">
              {" "}
              <img
                src={img1}
                alt=""
                onClick={() => {
                  setImagenSeleccionada(img1);
                }}
                className="img_tour_3"
              />
            </div>
            <div id="1" class="carousel-item">
              {" "}
              <img
                src={img2}
                alt=""
                onClick={() => {
                  setImagenSeleccionada(img2);
                }}
                className="img_tour_3"
              />
            </div>
            <div id="2" class="carousel-item">
              {" "}
              <img
                src={img3}
                alt=""
                onClick={() => {
                  setImagenSeleccionada(img3);
                }}
                className="img_tour_3"
              />
            </div>
            <div id="3" class="carousel-item">
              {" "}
              <img
                src={img4}
                alt=""
                onClick={() => {
                  setImagenSeleccionada(img4);
                }}
                className="img_tour_3"
              />
            </div>
            <div id="4" class="carousel-item">
              {" "}
              <img
                src={img5}
                alt=""
                onClick={() => {
                  setImagenSeleccionada(img5);
                }}
                className="img_tour_3"
              />
            </div>
            <div id="5" class="carousel-item">
              {" "}
              <img
                src={img6}
                alt=""
                onClick={() => {
                  setImagenSeleccionada(img6);
                }}
                className="img_tour_3"
              />
            </div>
            <div id="6" class="carousel-item">
              {" "}
              <img
                src={img7}
                alt=""
                onClick={() => {
                  setImagenSeleccionada(img7);
                }}
                className="img_tour_3"
              />
            </div>
            <div id="7" class="carousel-item">
              {" "}
              <img
                src={img8}
                alt=""
                onClick={() => {
                  setImagenSeleccionada(img8);
                }}
                className="img_tour_3"
              />
            </div>
            <div id="8" class="carousel-item">
              {" "}
              <img
                src={img9}
                alt=""
                onClick={() => {
                  setImagenSeleccionada(img9);
                }}
                className="img_tour_3"
              />
            </div>
            <div id="9" class="carousel-item">
              {" "}
              <img
                src={img10}
                alt=""
                onClick={() => {
                  setImagenSeleccionada(img10);
                }}
                className="img_tour_3"
              />
            </div>
            <div id="10" class="carousel-item">
              {" "}
              <img
                src={img11}
                alt=""
                onClick={() => {
                  setImagenSeleccionada(img11);
                }}
                className="img_tour_3"
              />
            </div>
            <div id="11" class="carousel-item">
              {" "}
              <img
                src={img12}
                alt=""
                onClick={() => {
                  setImagenSeleccionada(img12);
                }}
                className="img_tour_3"
              />
            </div>
            <div id="12" class="carousel-item">
              {" "}
              <img
                src={img13}
                alt=""
                onClick={() => {
                  setImagenSeleccionada(img13);
                }}
                className="img_tour_3"
              />
            </div>
          </div>
        </div>
        <div className="caja_4">
          <div
            id="efecto"
            style={{
              display: "flex",
              justifyContent: "center",
              flexWrap: "wrap",
            }}
          >
            <div className="caja_5">
              <img
                src={icon1}
                alt=""
                style={{ width: "42px", height: "42px" }}
              />
              <p
                className="titulo_5"
                style={{ color: "#148D8D", margin: "0px" }}
              >
                DURACION
              </p>
              <p
                className="texto_1"
                style={{ margin: "0px", color: "#555555" }}
              >
                3.5 horas aprox.
              </p>
            </div>
            {/* <div className="caja_5">
              <img
                src={icon2}
                alt=""
                style={{ width: "28px", height: "43px" }}
              />
              <p
                className="titulo_5"
                style={{ color: "#D83951", margin: "0px" }}
              >
                PRECIO
              </p>
              <p
                className="texto_1"
                style={{
                  margin: "0px",
                  color: "#555555",
                  textDecoration: "line-through",
                }}
              >
                Desde $70 USD
              </p>
              <p
                className="texto_6"
                style={{ margin: "0px", color: "#D83951" }}
              >
                <b>OFERTA $55 USD</b>
              </p>
            </div> */}
            <div className="caja_5">
              <img
                src={icon3}
                alt=""
                style={{ width: "73px", height: "44px" }}
              />
              <p
                className="titulo_5"
                style={{ color: "#FDBB41", margin: "0px" }}
              >
                GRUPO
              </p>
              <p
                className="texto_1"
                style={{ margin: "0px", color: "#555555" }}
              >
                7 personas max.
              </p>
            </div>
          </div>
        </div>
        <div className="cont_5 texto_1" style={{ color: "#555555" }}>
          <p className="box_4">
            Si es la primera vez que nos visitas NO PUEDE FALTAR en tu viaje, ya
            que te mostraremos desde el Cartel de Welcome to Las Vegas hasta el
            Fremont Street Experience (ciudad vieja), desde el sur hasta el
            norte de la ciudad.
          </p>
          <p className="box_4">
            Y si no es la primera vez que nos visitas pero no has tomado este
            tour, créenos cuando te decimos ¡Te falta mucho que ver!
          </p>
          <div className="row cont_6">
            <div className="col s12 m4" style={{ padding: "0px" }}>
              <p className="titulo_7" style={{ marginBottom: "15px" }}>
                INCLUYE
              </p>
              <div
                style={{
                  display: "flex",
                  justifyContent: "left",
                  alignItems: "center",
                }}
              >
                <img
                  src={icon4}
                  alt=""
                  style={{ width: "30px", height: "30px" }}
                />
                <p
                  className="texto_1"
                  style={{ color: "#555555", margin: "5px 10px" }}
                >
                  Salida desde tu hotel
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "left",
                  alignItems: "center",
                }}
              >
                <img
                  src={icon4}
                  alt=""
                  style={{ width: "30px", height: "30px" }}
                />
                <p
                  className="texto_1"
                  style={{ color: "#555555", margin: "5px 10px" }}
                >
                  Traslado ida y vuelta
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "left",
                  alignItems: "center",
                }}
              >
                <img
                  src={icon4}
                  alt=""
                  style={{ width: "30px", height: "30px" }}
                />
                <p
                  className="texto_1"
                  style={{ color: "#555555", margin: "5px 10px" }}
                >
                  Agua
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "left",
                  alignItems: "center",
                }}
              >
                <img
                  src={icon4}
                  alt=""
                  style={{ width: "30px", height: "30px" }}
                />
                <p
                  className="texto_1"
                  style={{ color: "#555555", margin: "5px 10px" }}
                >
                  Tour 100% en Español
                </p>
              </div>
            </div>
          </div>
          <div className="caja_6">
            <p className="titulo_7" style={{ marginBottom: "0px" }}>
              INTINERARIO
            </p>
            <p className="texto_1" style={{ color: "#555555" }}>
              Nuestro Tour a Las Vegas de noche comienza en la puerta de tu
              hotel en Las Vegas, allí nuestro guía de habla hispana pasará por
              ti en una cómoda van turística.
            </p>
          </div>
          <div className="box_intinerario">
            <div className="caja_inti">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <div className="linea_arriba_inv" />
                <div className="forma_3">1</div>
                <div className="linea_abajo" />
              </div>
              <div>
                <div className="box_intinerario_2">
                  <div className="texto_1">
                    {window.screen.width <= 850 ? (
                      <img
                        src={img4}
                        alt=""
                        className="img_tour_4"
                        style={{ float: "left" }}
                      />
                    ) : (
                      <img
                        src={img14}
                        alt=""
                        className="img_tour_4"
                        style={{ float: "left" }}
                      />
                    )}
                    <p className="texto_5">WELCOME TO LAS VEGAS</p>
                    <p>
                      Arrancamos entre 6:30 a 7:00 pm y nuestra primera parada
                      es en el famoso letrero de <b>Welcome to Las Vegas</b>, el
                      cual es uno de los letreros más fotografiados a nivel
                      mundial.
                    </p>
                    <p>
                      El no tener una foto aquí es prácticamente como si nunca
                      hubieras venido, definitivamente no te lo puedes perder.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="caja_inti">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <div className="linea_arriba" />
                <div className="forma_3">2</div>
                <div className="linea_abajo" />
              </div>
              <div>
                <div className="box_intinerario_2">
                  <div className="texto_1">
                    {window.screen.width <= 850 ? (
                      <img src={img9} alt="" className="img_tour_4" />
                    ) : (
                      <img src={img15} alt="" className="img_tour_4" />
                    )}
                    <p className="texto_5" style={{ marginTop: "40px" }}>
                      LUXOR HOTEL
                    </p>
                    <p>
                      Continuamos al majestuoso <b>Hotel Luxor</b> donde
                      podremos admirar su impactante pirámide negra de 30 pisos
                      y explorar más de este hotel que está dentro de los 10
                      hoteles más grandes del mundo.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="caja_inti">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <div className="linea_arriba" />
                <div className="forma_3">3</div>
                <div className="linea_abajo" />
              </div>
              <div>
                <div className="box_intinerario_2">
                  <div className="texto_1">
                    {window.screen.width <= 850 ? (
                      <img src={img10} alt="" className="img_tour_4" />
                    ) : (
                      <img src={img16} alt="" className="img_tour_4" />
                    )}
                    <p className="texto_5" style={{ marginTop: "40px" }}>
                      FUENTES DEL BELLAGIO
                    </p>
                    <p>
                      Las fuentes del <b>Hotel Bellagio</b> son uno de los
                      puntos más importantes de tu viaje, disfruta el
                      espectáculo que se realiza con más de 1,200 chorros de
                      agua, de diferentes dimensiones, bailando al sonido de la
                      música.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="caja_inti">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <div className="linea_arriba" />
                <div className="forma_3">4</div>
                <div className="linea_abajo" />
              </div>
              <div>
                <div className="box_intinerario_2">
                  <div className="texto_1">
                    {window.screen.width <= 850 ? (
                      <img src={img8} alt="" className="img_tour_4" />
                    ) : (
                      <img src={img17} alt="" className="img_tour_4" />
                    )}
                    <p className="texto_5" style={{ marginTop: "40px" }}>
                      THE VENETIAN
                    </p>
                    <p>
                      Exploraremos uno de los hoteles más espectaculares de Las
                      Vegas, reconocido por su ambiente de lujo. Vas a poder ver
                      recreaciones de los principales puntos de interés de la
                      ciudad de Venecia, como el puente Rialto o la plaza de San
                      Marcos y hasta podrás ver una recreación del gran Canal.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="caja_inti">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <div className="linea_arriba" />
                  <div className="forma_3">5</div>
                  <div className="linea_abajo_inv" />
                </div>
                <div>
                  <div className="box_intinerario_2">
                    <div className="texto_1">
                      {window.screen.width <= 850 ? (
                        <img src={img7} alt="" className="img_tour_4" />
                      ) : (
                        <img src={img18} alt="" className="img_tour_4" />
                      )}
                      <p className="texto_5" style={{ marginTop: "40px" }}>
                        LAS VEGAS VIEJAS
                      </p>
                      <p>
                        Conocido también como el <b>Downtown de Las Vegas</b>,
                        es sin duda el lugar más divertido de la ciudad. Ahí
                        exploraremos la calle <b>Fremont</b>, en donde seremos
                        testigos de la pantalla más grande del mundo Fremont{" "}
                        <b>Street Experience</b> es uno de los espectáculos más
                        importantes de Las Vegas. La experiencia combina a la
                        perfección luces y sonido, además de convertirse en el
                        escenario de diferentes conciertos y actuaciones.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="caja_inti">
                <div className="caja_9"></div>
                <div className="caja_8">
                  <div className="box_intinerario_2">
                    <div className="texto_1">
                      <p>
                        Además ahí encontraremos un sin fin de curiosidades,
                        como la pepita de oro más grande del mundo,
                        disfrutaremos de música en vivo y comprenderemos que la
                        verdadera esencia de la ciudad nace en{" "}
                        <b>Fremont Street.</b>
                      </p>
                      <p>
                        Definitivamente no puedes perderte esta experiencia.
                      </p>
                      <p>
                        Damos por finalizado el tour y nos vamos de regreso
                        hasta la entrada de tu hotel para que sigas disfrutando
                        de la noche.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="img_vegas">
          <p className="titulo_1" style={{ fontSize: "53px" }}>
            ¿Te interesa este tour?
          </p>
          <a href="/contacto">
            <button type="" className="btn_3">
              <p>CONTÁCTANOS</p>
            </button>
          </a>
        </div>
      </div>
    </Fragment>
  );
};

export default NochesVegas;

import React, { Fragment, Component, useEffect } from "react";
import $ from "jquery";

import img1 from "../Images/escritorio/Nosotros/NE2.png";

import icon1 from "../Images/iconos/Nosotros/NI1.png";
import icon2 from "../Images/iconos/Nosotros/NI2.png";
import icon3 from "../Images/iconos/Nosotros/NI3.png";
import icon4 from "../Images/iconos/Nosotros/NI4.png";
import icon5 from "../Images/iconos/Nosotros/NI5.png";

const Nosotros = () => {
  useEffect(() => {
    $(window).scroll(function () {
      $("#efecto").each(function () {
        let efecto = $(this).offset().top;
        let topOfWindow = $(window).scrollTop();
        if (efecto < topOfWindow + 800) {
          $(this).addClass("fadeIn");
        }
      });
      $("#efecto_2").each(function () {
        let efecto = $(this).offset().top;
        let topOfWindow = $(window).scrollTop();
        if (efecto < topOfWindow + 1000) {
          $(this).addClass("fadeIn");
        }
      });
    });
  }, []);

  return (
    <Fragment>
      <div className="img_nosotros">
        <p className="titulo_1">Sobre nosotros</p>
      </div>

      <div className="flex_nosotros_1">
        <div className="box_1">
          <div className="img_nosotros_2" />
        </div>
        <div className="box_2">
          <p className="titulo_2">¡LATINO VEGAS!</p>
          <div className="texto_1">
            <p>
              Somos una empresa especializada en el turismo por la ciudad de Las
              Vegas, tenemos más de 7 años creando las mejores experiencias para
              todos nuestros viajeros durante su estancia en esta hermosa
              ciudad.
            </p>
            <p>
              Nuestra diversión, calidad y profesionalismo es reconocido por
              todos nuestros clientes.
            </p>
          </div>
          <div className="caja_1">
            <a href="/tours">
              <button type="" className="btn_1">
                <p>CONOCE LOS TOURS</p>
              </button>
            </a>
          </div>
        </div>
      </div>
      <div className="cont_1">
        <div
          id="efecto"
          style={{
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
          }}
        >
          <div className="box_3">
            <div style={{ overflow: "hidden" }}>
              <img src={icon1} alt="" className="icon_nosotros" />
            </div>
            <p className="texto_2" style={{ margin: "10px 0px" }}>
              Nuestra esencia
            </p>
            <p
              className="texto_1"
              style={{
                fontSize: "17px",
                marginTop: "0px",
                textAlign: "center",
              }}
            >
              Nos caracterizamos por nuestra
              <br />
              profesionalidad y por priorizar al <br />
              máximo la creación de experiencias <br />
              inolvidables a nuestros clientes.
            </p>
          </div>
          <div className="box_3">
            <img src={icon2} alt="" className="icon_nosotros" />
            <p className="texto_2" style={{ margin: "10px 0px" }}>
              Al estilo latino
            </p>
            <p
              className="texto_1"
              style={{
                fontSize: "17px",
                marginTop: "0px",
                textAlign: "center",
              }}
            >
              Entregamos lo mejor de nosotros para <br />
              que disfrutes TODA tu estancia en <br />
              Las Vegas.
            </p>
          </div>
          <div className="box_3">
            <img src={icon3} alt="" className="icon_nosotros" />
            <p className="texto_2" style={{ margin: "10px 0px" }}>
              Solo en español
            </p>
            <p
              className="texto_1"
              style={{
                fontSize: "17px",
                marginTop: "0px",
                textAlign: "center",
              }}
            >
              La mejor y más divertida experiencia <br />
              garantizada 100% en tu idioma.
              <br />
            </p>
          </div>
          <div className="box_3">
            <img src={icon4} alt="" className="icon_nosotros" />
            <p className="texto_2" style={{ margin: "10px 0px" }}>
              Los mejores precios
            </p>
            <p
              className="texto_1"
              style={{
                fontSize: "17px",
                marginTop: "0px",
                textAlign: "center",
              }}
            >
              Ofrecemos los mejores precios en <br />
              nuestros tours de muy alta calidad y <br />
              lujo, vivirás una experiencia increíble.
            </p>
          </div>
          <div className="box_3">
            <img src={icon5} alt="" className="icon_nosotros" />
            <p className="texto_2" style={{ margin: "10px 0px" }}>
              Transporte de lujo
            </p>
            <p
              className="texto_1"
              style={{
                fontSize: "17px",
                marginTop: "0px",
                textAlign: "center",
              }}
            >
              Está equipado con todo lo necesario <br />
              para estar cómodo y seguro durante <br />
              nuestras aventuras.
            </p>
          </div>
        </div>
      </div>

      <div className="img_nosotros_3">
        <p className="titulo_nosotros_2">Viva Las Vegas</p>
      </div>
    </Fragment>
  );
};

export default Nosotros;

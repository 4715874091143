import React, {Fragment, Component}  from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import Header from '../../../Components/SideNav';
import { Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import firebaseHelper from '../../../Helpers/Firebase';


class Productos extends Component {
    
    constructor(props){
        super(props)
        this.state = {
            nombre: ''
        }
    }

    eliminar = (id, urlImagen) => {
        const { firestore } = this.props;
        const { firebase } = this.props;

        firebaseHelper.eliminarImagen(id, 'producto', firestore, firebase, urlImagen);
        
    }


    render(){
        if (!this.props.categoria) {
            return (
                <Fragment>
                    <Header />
                    <div className="container center-align" style={{marginTop:'20%', marginBottom:'20%'}}>
                        <div class="preloader-wrapper big active">
                            <div class="spinner-layer spinner-red darken-4-only">
                                <div class="circle-clipper left">
                                    <div class="circle"></div>
                                </div>
                                <div class="gap-patch">
                                    <div class="circle"></div>
                                </div>
                                <div class="circle-clipper right">
                                    <div class="circle"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>
            );
        } else {
            return (
                <Fragment>
                    <Header mensaje="PRODUCTOS" />
                    <div className="btn-outline-light container" style={{padding: '30px 0 30px 0'}}>
                        <Link to={`/admin/nuevo-producto/${this.props.match.params.id}`} id="font" class="btn waves-effect waves-light" style={{padding:'0 50px 0 50px', background:'#e57373 '}}>AGREGAR PRODUCTO</Link>
                        <Link to={'/admin/categorias'} id="font-button" class="btn waves-effect waves-light" style={{padding:'0 50px 0 50px', marginLeft:'20px', background:'#e57373 '}}>REGRESAR</Link>
                    </div>
                    <div className="container">
                        {this.props.producto === undefined ? (
                            window.location.reload()
                        ) : (
                            this.props.producto.length === 0 ? (
                                <div className="center">
                                    <h2 id="font">No hay productos registrados</h2>
                                </div>
                            ) : (
                                <div>
                                    <h5 id="font">{this.props.categoria.subCategoria}</h5>
                                    <Table striped bordered hover style={{marginBottom:'50px'}}>
                                        <thead>
                                            <tr>
                                                <th>Nombre</th>
                                                <th>Menudeo</th>
                                                <th>Mayoreo</th>
                                                <th>Cantidad</th>
                                                <th>Acciones</th>
                                            </tr>
                                        </thead>
                                        <tbody id="font">
                                            {this.props.producto.map(dato => (
                                                <tr key={dato.id}>
                                                <td>{dato.nombre}</td>
                                                <td>$ {dato.precio}</td>
                                                <td>$ {dato.precioMayoreo}</td>
                                                <td>{dato.cantidad}</td>
                                                <td>
                                                    <a style={{marginLeft:'14%'}} href={`/admin/editar-producto/${dato.id}`}><i class="material-icons" style={{color:'#e57373 '}}>create</i></a>
                                                    <div onClick={ () => this.eliminar(dato.id, dato.urlImagen)}  style={{marginLeft:'14%'}}><i class="material-icons" style={{color:'#e57373 '}}>delete</i></div>
                                                </td>
                                            </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </div>
                            )
                        )}
                    </div>
                    
                </Fragment>
            );
        }
    }
}

Productos.protoType = {
    firestor : PropTypes.object.isRequired
}

export default compose(
    firestoreConnect(props => [ 
        {
            collection: 'producto',
            where: [
                ['categoria', '==', props.match.params.id ]
            ],
            //orderBy: 'nombre'
        },
        {
            collection: 'categoria',
            doc: props.match.params.id
        }
    ]),
    connect(({ firestore: { ordered }}, props ) => ({
        producto: ordered.producto,
        categoria: ordered.categoria && ordered.categoria[0]
    }))
)(Productos);
import React, { Fragment } from "react";

import img1 from "../Images/escritorio/Tours/TE4.png";
import img2 from "../Images/escritorio/Tours/TE5.png";
import img3 from "../Images/escritorio/Tours/TE7.jpg";

import icon1 from "../Images/iconos/Tours/TI1.png";

const Tour = () => {
  const Tours = [
    {
      nombre: <h1>TOUR AL GRAN CAÑON</h1>,
      url: img1,
      precio: "155",
      duracion: "8",
      vinculo: "/tours-gran-canion",
      contenido: [
        {
          cont: 'Letrero "Welcome to Las Vegas',
          opc: 0,
        },
        {
          cont: "Bosque Joshua Tree.",
          opc: 0,
        },
        {
          cont: "Eagle Point.",
          opc: 0,
        },
        {
          cont: "Skywalk",
          opc: 1,
        },
        {
          cont: "Sky View Restaurant.",
          opc: 0,
        },
        {
          cont: "Guano Point",
          opc: 0,
        },
      ],
    },
    {
      nombre: (
        <h1>
          7 MONTAÑAS <br /> MAGICAS
        </h1>
      ),
      url: img3,
      precio: "40",
      duracion: "2",
      vinculo: "/tours-7-montanias-magicas",
      contenido: [
        {
          cont: "Siete Montañas Mágicas",
          opc: 0,
        },
        {
          cont: 'Letrero "Las Vergas Showgirl"',
          opc: 0,
        },
        {
          cont: 'Letrero "Archway"',
          opc: 0,
        },
      ],
    },
    {
      nombre: (
        <h1>
          DE NOCHE POR <br /> LAS VEGAS
        </h1>
      ),
      url: img2,
      precio: "55",
      duracion: "3:30",
      vinculo: "/tours-noche-por-las-vegas",
      contenido: [
        {
          cont: "Welcome to Las Vegas",
          opc: 0,
        },
        {
          cont: "Hotel Luxor",
          opc: 0,
        },
        {
          cont: "Fuentes Bellagio",
          opc: 0,
        },
        {
          cont: "The Mirage Volcano",
          opc: 0,
        },
        {
          cont: "DownTown - Fremont St",
          opc: 0,
        },
      ],
    },
  ];

  return (
    <Fragment>
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
          }}
        >
          {Tours.map((tour) => (
            <div className="box_tour">
              <div className="box_tour_2">
                <div>{tour.nombre}</div>
              </div>
              <img src={tour.url} alt="" className="img_tour" />
              <div className="box_tour_3">
                <div style={{ width: "50%", textAlign: "center" }}>
                  {/* <p>
                    DESDE <span>${tour.precio} USD</span>
                  </p> */}
                </div>
                <div className="forma_1" />
              </div>
              <div className="caja_tour_contenido">
                {tour.contenido.map((conten) =>
                  conten.opc === 0 ? (
                    <div>
                      <p>{conten.cont}</p>
                    </div>
                  ) : (
                    <div>
                      <span>{conten.cont} (opcional)</span>
                    </div>
                  )
                )}
              </div>
              <div className="box_tour_4">
                <p>DURACION APROX.</p>
                <img src={icon1} alt="" className="icon_tour" />
                <span>{tour.duracion} horas</span>
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <a href={tour.vinculo}>
                  <div className="btn_tour">
                    <p>CONÓCE MÁS</p>
                  </div>
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Fragment>
  );
};

export default Tour;

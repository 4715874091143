import React, {Fragment, Component}  from 'react';
import { firestoreConnect } from 'react-redux-firebase';
import Header from '../../../Components/SideNav';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import CryptoJS from "react-native-crypto-js";
import firebaseHelper from '../../../Helpers/Firebase';


class NuevoUsuario extends Component {
    
    constructor(props){
        super(props)
        this.state = {
            correo: '',
            password: '',
            tipoMostrar: '',
            tipo: '',
            nombre: ''
        }
    }

    agregar = e => {
        const { history } = this.props;
        e.preventDefault();

        let ciphertext = CryptoJS.AES.encrypt(this.state.password, 'Y2Fhdg==').toString();
        var tipoMostrar;

        if (this.state.tipo === '0') {
            tipoMostrar = "Administrador"
        } else if (this.state.tipo === '1') {
            tipoMostrar = "Otro"
        }

        this.setState({
            password: ciphertext,
            tipoMostrar: tipoMostrar
        }, () => {
            //extraer valores del state
            const nuevo = {...this.state};
    
            //extraer firestor de props
            const { firestore } = this.props;
    
            //guardado en la base de datos
            firebaseHelper.agregar('usuario', firestore, nuevo, '/admin/usuarios', history);
        });

    }

    leerDato = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    render(){
        return (
            <Fragment>
                <Header mensaje="NUEVO USUARIO" />
                <div id="font" className="container">    
                    <div className="center" style={{padding: '30px 0 30px 0'}}>
                        <form onSubmit={this.agregar}>
                            <div style={{margin:'0 15%'}}>
                                <p id="font-text-b" >Tipo Usuario</p>
                                <div style={{display:'flex', justifyContent:'center'}}>    
                                    <p style={{marginLeft:'10px'}}>
                                        <label style={{marginLeft:'30px'}}>
                                            <input required onChange={this.leerDato} value='0' class="with-gap" name="tipo" type="radio"  />
                                            <span>Administrador</span>
                                        </label>
                                    </p>
                                    <p>
                                        <label style={{marginLeft:'30px'}}>
                                            <input required onChange={this.leerDato} value='1' class="with-gap" name="tipo" type="radio"  />
                                            <span>Otro</span>
                                        </label>
                                    </p>
                                </div>
                                <div class="input-field col s12">
                                    <input required onChange={this.leerDato} name="nombre" id="nombre" type="text" class="validate" />
                                    <label for="nombre">Nombre</label>
                                </div>
                                <div class="input-field col s12">
                                    <input required onChange={this.leerDato} name="correo" id="correo" type="email" class="validate" />
                                    <label for="correo">Correo</label>
                                </div>
                                <div class="input-field col s12">
                                    <input required onChange={this.leerDato} name="password" id="password" type="password" class="validate" />
                                    <label for="password">Password</label>
                                </div>
                            </div>
                            <button id="font-button" class="btn waves-effect waves-light" type="submit" name="action" style={{padding:'0 50px 0 50px', background:'#e57373 '}}>AGREGAR</button>
                            <Link to={'/admin/usuarios'} id="font-button" class="btn waves-effect waves-light" style={{padding:'0 50px 0 50px', marginLeft:'20px', background:'#e57373 '}}>REGRESAR</Link>
                        </form>
                    </div>
                </div>
            </Fragment>
        );
    }
}

NuevoUsuario.protoType = {
    firestor : PropTypes.object.isRequired
}

export default firestoreConnect() (NuevoUsuario);
//integracion de firestore en el proyecto
import { createStore, combineReducers, compose } from 'redux';
import { reactReduxFirebase, firebaseReducer } from 'react-redux-firebase';
import { reduxFirestore, firestoreReducer } from 'redux-firestore';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage'

// configurar firestore
const firebaseConfig = {
    apiKey: "AIzaSyC1pvRK6utX9O0IBRjTK2gQKIkmgNXeoW0",
    authDomain: "michel-alvarez-studio.firebaseapp.com",
    projectId: "michel-alvarez-studio",
    storageBucket: "michel-alvarez-studio.appspot.com",
    messagingSenderId: "173490469615",
    appId: "1:173490469615:web:336752c8d8050fdfd8391a",
    measurementId: "G-59NQKT9E7N"
}

//configuracion de react-redux
const rrfConfig = {
    userProfile: 'users',
    useFirestoreForProfile: true
}

// inicializar firebase
firebase.initializeApp(firebaseConfig);

// Initialize other services on firebase instance
firebase.firestore() // <- needed if using firestore

// Reducers
const rootReducer = combineReducers({
    firebase: firebaseReducer,
    firestore: firestoreReducer
})

// crear el anlace con compose de redux y firestore
const createStoreWithFirebase = compose(
    reactReduxFirebase(firebase, rrfConfig),
    reduxFirestore(firebase)
)(createStore);

// state inicial
const initialState = {};

//crear el store
const store = createStoreWithFirebase(rootReducer, initialState)


export default store;